import React, {
  useEffect,
  useState
} from "react";
import {
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  LinearProgress,
  useMediaQuery,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";

import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "./Services.css";
import { Link, useLocation } from "react-router-dom";
import { get_area, get_service } from "../../api/API";

import bannerServices from "../../assets/images/bannerServices.png";
import $, { nodeName } from "jquery";

import { get_service_list } from '../../api/API'

export default function Services() {
  let { Services } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Services]);

  const [loading, setLoading] = useState(false);
  const [joblist, setJoblist] = useState(null);

  const getData = async () => {
    setLoading(true);
    var resJob = await get_service_list({});
    if (resJob.status) {
      setJoblist(resJob.data)
      setLoading(false);
    }
  }

  // const onImgLoad = ({ target: img }) => {
  //   const { offsetHeight, offsetWidth } = img;
  //   console.log(offsetWidth)
  //   $(".resizeIMG").height(offsetWidth);
  // };

  useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <Header />
      <div className="theBackgroundService">
        <img src={bannerServices} />
        <div className="theOverlay">
          <div className="theTitle">
            <p>OUR SERVICES</p>
          </div>
        </div>
      </div>
      <Container>
        <div className="theServiceSection">
          <div className="theServicesList">
            <Grid container spacing={3}>

              {joblist != null && joblist.map((res) =>
                <Grid item xs={6} sm={3} md={3}>
                  <Link className="theServiceCard" to={`../servicedetail/${res.service_list_id}`}>
                    <div className="theServiceIMG">
                      <img className="resizeIMG"
                        //  onLoad={onImgLoad} 
                        src={res.product_image}
                      />
                    </div>
                    <p className="name">{res.name}</p>
                  </Link>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
