import React from "react";
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Profile.css";

import avatar from "../../assets/images/avantar.png";
import coin from "../../assets/images/coin.svg";
import point from "../../assets/images/point.svg";
import refer from "../../assets/images/refer.png";
import { get_profile, edit_profile } from "../../api/API";

export default function Profile() {

  const history = useHistory()

  let { Profile } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Profile]);


  const [day, setDay] = React.useState("");
  const handleChangeDay = (event) => {
    setDay(event.target.value);
  };
  const [tab, setTab] = React.useState("Latest");
  const handleChangeTab = (text) => {
    setTab(text);
  };

  //

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const [token, setToken] = React.useState("");
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    let postparam = {
      token: token,
    };
    get_profile(postparam).then((json) => {
      setUser(json.data[0]);
    });
  }, []);


  const handleEditProfile = () => {
    edit_profile(user).then((json) => {
      if (json.status) {
        alert("Your profile has been updated!");
        history.push("/");
      } else {
        alert(json.message);
      }
    });
  };


  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theProfileSection">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <div className="theUserCard">
                <div className="theCardHeader">
                  <div className="theAvatar">
                    {/* <img src={avatar} /> */}
                    <p>{user.name}</p>
                  </div>
                </div>
                <div className="theCardBody">
                  <Link className="theCoin" to="/coinreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={coin} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Coins</p>
                        <p className="coin">9,000 Coins</p>
                      </div>
                    </div>
                  </Link>
                  {/* <Link className="theCoin" to="/pointreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={point} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Points</p>
                        <p className="point">9,000 Points</p>
                      </div>
                    </div>
                  </Link> */}
                  <div class="theLink">
                    <Link className="theNavLinkActive" to="/profile">
                      My Profile
                    </Link>
                    <Link className="theNavLink" to="/address">
                      Address
                    </Link>
                    <Link className="theNavLink" to="/order">
                      Order History
                    </Link>
                    <Link className="theNavLink" to="/referfriend">
                      Refer a Friend
                    </Link>
                    <Link className="theNavLink" to="/becomeconstructor">
                      Become Constructor
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <div className="theCardCon">
                <div className="theCardConHeader">
                  <p>My Profile</p>
                </div>
                <div className="theCardConBody">
                  <div className="">
                    <Grid container spacing={3} className="theFlexDirection">
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="theForm">
                          {/* <div className="theFormGroup">
                            <p className="label">Profile Picture</p>
                            <TextField className="theFormControl" type="file" />
                          </div> */}
                          <div className="theFormGroup">
                            <p className="label">Name</p>
                            <TextField
                              className="theFormControl"
                              placeholder="Name"
                              onChange={handleChange}
                              name="name"
                              value={user.name}
                            />
                          </div>

                          <div className="theFormGroup">
                            <p className="label">Phone</p>
                            <TextField
                              className="theFormControl"
                              placeholder="Phone"
                              onChange={handleChange}
                              value={user.contact}
                              name="contact"
                              disabled
                            />
                          </div>

                          <div className="theFormGroup">
                            <p className="label">Email</p>
                            <TextField
                              className="theFormControl"
                              placeholder="Email"
                              onChange={handleChange}
                              name="email"
                              value={user.email}
                            />
                          </div>
                          {/* <div className="theFormGroup">
                            <p className="label">Date of Birth</p>
                            <TextField
                              className="theFormControl"
                              placeholder="Date of Birth"
                              type="date"
                            />
                          </div> */}
                          <div className="theBtn">
                            <a className="theEdit" onClick={() => handleEditProfile()}>Save</a>
                          </div>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6}>
                        <div className="theForm">
                          <div className="theFormGroup">
                            <p className="label">Old Password</p>
                            <TextField
                              className="theFormControl"
                              placeholder="Password"
                              type="password"
                            />
                          </div>
                          <div className="theFormGroup">
                            <p className="label">New Password</p>
                            <TextField
                              className="theFormControl"
                              placeholder="New Password"
                              type="password"
                            />
                          </div>
                          <div className="theFormGroup">
                            <p className="label">Confirm Password</p>
                            <TextField
                              className="theFormControl"
                              placeholder="Confirm Password"
                              type="password"
                            />
                          </div>

                        </div>
                      </Grid> */}
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <div className="theTransaction">
            <div className="theCardCon">
              <div className="theCardConHeader">
                <p>Transactions</p>
              </div>
              <div className="theCardConBody">
                <div className="theSubHeader">
                  <div className="theTabList">
                    <Link
                      className={
                        tab === "Latest" ? "theLastestActive" : "theLastest"
                      }
                      onClick={() => handleChangeTab("Latest")}
                    >
                      Latest
                    </Link>
                    <Link
                      className={
                        tab === "Archived" ? "theArchivedActive" : "theArchived"
                      }
                      onClick={() => handleChangeTab("Archived")}
                    >
                      Archived
                    </Link>
                  </div>
                  <div className="theFilterDay">
                    <FormControl className="">
                      <Select
                        value={day}
                        onChange={handleChangeDay}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Last 7 days"}>Last 7 days</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="theTransactionList">
                  {tab === "Latest" && (
                    <>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {tab === "Archived" && (
                    <>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
