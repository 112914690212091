import React from "react";
import {
  Container,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import logo from "../../assets/images/easysettle.png";
import "./Register.css";
import { get_state, register } from "../../api/API";

export default function Register() {
  const history = useHistory();
  let { Register } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Register]);


  // GET STATE

  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    // get_state().then((json) => {
    //   setState(json.data);
    // });
  }, []);

  // REGISTER

  const [registerData, setRegisterData] = React.useState({
    contact: "",
    name: "",
    email: "",
    password: "",
    password_2: "",
  });

  const handleChange = (event) => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
  };

  const handleRegister = () => {
    if (
      registerData.name.length == 0 ||
      registerData.email.length == 0 ||
      registerData.contact.length == 0 ||
      registerData.password.length == 0 ||
      registerData.password_2.length == 0
    ) {
      alert("All Field Required");
      return;
    }
    if (registerData.password !== registerData.password_2) {
      alert("Password Not Same");
    }
    register(registerData).then((json) => {
      if (json.status) {
        alert(json.message);
        history.push("/home");
      }
    });
  };
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theRegisterSection">
          <div className="theRegisterBox">
            <div className="theForm">
              <div className="theLogo">
                <img src={logo} />
              </div>
              {/* <p className="title">Register</p> */}

              <div className="theBoxes">
                <div className="theFormGroup">
                  <p className="label">Email</p>
                  <TextField
                    className="theFormControl"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                  />
                </div>
                <div className="theFormGroup">
                  <p className="label">Full Name</p>
                  <TextField
                    className="theFormControl"
                    placeholder="Full Name"
                    name="name"
                    onChange={handleChange}
                  />
                </div>
                <div className="theFormGroup">
                  <p className="label">Phone Number</p>
                  <TextField
                    className="theFormControl"
                    placeholder="Phone Number"
                    name="contact"
                    onChange={handleChange}
                  />
                </div>
                <div className="theFormGroup">
                  <p className="label">Password</p>
                  <TextField
                    className="theFormControl"
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                  />
                  {/* <p className="alert">
                    *Must contain 8 characters at least 1 uppercase, lowercase,
                    digit and symbol
                  </p> */}
                </div>
                <div className="theFormGroup">
                  <p className="label">Reconfirm Password</p>
                  <TextField
                    className="theFormControl"
                    placeholder="Reconfirm Password"
                    type="password"
                    name="password_2"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="theCreateAccount">
                <a
                  className="theCreateAccountBtn"
                  onClick={()=>handleRegister()}
                >
                  CREATE ACCOUNT
                </a>
                {/* <p>
                  By clicking this button, you agree to our
                  <Link className="theNavLink"> Terms</Link>,
                  <Link className="theNavLink"> Privacy Policy</Link> and
                  <Link className="theNavLink"> Security Policy</Link>.
                </p> */}
                <p>
                  Already have account?
                  <a className="theNavLink" href="/login">
                     Click Here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
