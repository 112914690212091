import React from "react";
import { Container, Grid, TextField } from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Compare.css";
import { Link, useLocation } from "react-router-dom";

import prod001 from "../../assets/images/wall-mounted.png";

export default function Compare() {
  let { Compare } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Compare]);
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="c-theSaveList">
          <div className="theflex">
            <h3>Saved Product</h3>
          </div>
          <div className="c-theItem">
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3} md={3}>
                <div className="c-theSliderBox">
                  <img src={prod001} />
                  <p className="title">Hisense</p>
                  <p className="sub">Eco-Friendly Aircon</p>
                  <p className="price">RM1,999.00</p>
                  <Link className="theComapreBtn">Compare</Link>
                </div>
              </Grid>
              
            </Grid>

            
          </div>
        </div>
        <div className="c-theCompareList">
          <div className="c-theProduct">
            <div className="theProduct">
              <img src={prod001} />
              <p>Eco-Friendly Aircon</p>
            </div>
            <div className="theProduct">
              <img src={prod001} />
              <p>Eco-Friendly Aircon</p>
            </div>
          </div>
          <div className="c-theTable">
            <div class="table table-striped">
              <div className="theList">
                <p>Horse Power</p>
              </div>
              <div className="theList_">
                <p>1.5</p>
                <p>1.5</p>
              </div>
              <div className="theList">
                <p>Dimension</p>
              </div>
              <div className="theList_">
                <p>200x200</p>
                <p>200x300</p>
              </div>
              <div className="theList">
                <p>Power Usage</p>
              </div>
              <div className="theList_">
                <p>240w</p>
                <p>1000w</p>
              </div>
              <div className="theList">
                <p>Horse Power</p>
              </div>
              <div className="theList_">
                <p>1.5</p>
                <p>1.5</p>
              </div>
              <div className="theList">
                <p>Horse Power</p>
              </div>
              <div className="theList_">
                <p>1.5</p>
                <p>1.5</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
