import React, { useState } from "react";
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import coin from "../../assets/images/coin.svg";
import "./BecomeConstructor.css";

export default function BecomeConstructor() {
  const [tab, setTab] = React.useState("Latest");
  const handleChangeTab = (text) => {
    setTab(text);
  };

  const [formData, setFormData] = useState({
    company_register_form: "",
    director_ic: "",
    name_card: ""
  })
  const handleUpload = (e, name) => {
    // setIsLoading(true);
    var postData = new FormData();
    console.log(e.target.files, name);
    if (e.target.files.length) {
      postData.append("file", e.target.files[0]);
      postData.append("filename", "company_register_form");
      // formData.append("users_id", get_user().users_id);
      // upload_users_file(postData).then(res => {

      //   // setIsLoading(false);

      //   setFormData({
      //     ...formData,
      //     company_register_form: e.target.files,
      //   });
      //   // setSSMFileName(e.target.files[0].name);

      // }).catch((err) => {
      //   alert("Something went wrong");
      //   // setIsLoading(false);
      // });
    }
  };

  const handleUploadImage = (e, name) => {
    console.log(e.target.files, name)
  }

  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theBecomeConstructorSection">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <div className="theUserCard">
                <div className="theCardHeader">
                  <div className="theAvatar">
                    {/* <img src={avatar} /> */}
                    <p>Name</p>
                  </div>
                </div>
                <div className="theCardBody">
                  <Link className="theCoin" to="/coinreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={coin} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Coins</p>
                        <p className="coin">9,000 Coins</p>
                      </div>
                    </div>
                  </Link>
                  {/* <Link className="theCoin" to="/pointreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={point} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Points</p>
                        <p className="point">9,000 Points</p>
                      </div>
                    </div>
                  </Link> */}
                  <div class="theLink">
                    <Link className="theNavLink" to="/profile">
                      My Profile
                    </Link>
                    <Link className="theNavLink" to="/address">
                      Address
                    </Link>
                    <Link className="theNavLink" to="/order">
                      Order History
                    </Link>
                    <Link className="theNavLink" to="/referfriend">
                      Refer a Friend
                    </Link>
                    <Link className="theNavLinkActive" to="/becomeconstructor">
                      Become Constructor
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <div className="theCardCon">
                <div className="theCardConHeader">
                  <p>Become Constructor</p>
                </div>
                <div className="theCardConBody">
                  <div className="">
                    <div className="theForm">
                      <div className="theFormGroup">
                        <p className="label">Company Register Form front & Back <a
                          style={{ color: '#e91e63', fontSize: '12px', fontStyle: "italic", }} href="#" target="_blank"
                        >*View Sample</a>
                        </p>
                        <TextField className="theFormControl" type="file" onChange={(e) => handleUpload(e, 'company_register_form')} />
                      </div>
                      <div className="theFormGroup">
                        <p className="label">Director IC front & Back <a
                          style={{ color: '#e91e63', fontSize: '12px', fontStyle: "italic", }} href="#" target="_blank"
                        >*View Sample</a>
                        </p>
                        <TextField className="theFormControl" type="file" onChange={(e) => handleUpload(e, 'director_ic')} />
                      </div>
                      <div className="theFormGroup">
                        <p className="label">Name Card <a
                          style={{ color: '#e91e63', fontSize: '12px', fontStyle: "italic", }} href="#" target="_blank"
                        >*View Sample</a>
                        </p>
                        <TextField className="theFormControl" type="file" onChange={(e) => handleUpload(e, 'name_card')} />
                      </div>
                      <br />
                      <div className="theDivider"></div>
                      <br />
                      <div className="theFormGroup">
                        <p className="labelbold">Sumbit photo of works:</p>
                      </div>

                      <div className="theFormGroup">
                        <p className="label">
                          Installation of PVC / Metal Casing
                        </p>
                        <TextField className="theFormControl"
                          inputProps={{
                            multiple: true
                          }} type="file" onChange={(e) => handleUploadImage(e)} />
                      </div>
                      <div className="theFormGroup">
                        <p className="label">Installation of Cable Tray</p>
                        <TextField className="theFormControl"
                          inputProps={{
                            multiple: true
                          }} type="file" onChange={(e) => handleUploadImage(e)} />
                      </div>
                      <div className="theFormGroup">
                        <p className="label">Aircond Piping Connection Point</p>
                        <TextField className="theFormControl"
                          inputProps={{
                            multiple: true
                          }} type="file" onChange={(e) => handleUploadImage(e)} />
                      </div>
                      <div className="theFormGroup">
                        <p className="label">Vacuum Pump</p>
                        <TextField className="theFormControl"
                          inputProps={{
                            multiple: true
                          }} type="file" onChange={(e) => handleUploadImage(e)} />
                      </div>
                      <div className="theFormGroup">
                        <p className="label">Bank Statements Title Head</p>
                        <TextField className="theFormControl"
                          inputProps={{
                            multiple: true
                          }} type="file" onChange={(e) => handleUploadImage(e)} />
                      </div>

                      <div className="theBtn">
                        <Link className="theSubmit">Submit</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
