import React from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import {
    Container,
    Grid,
    TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const PromotionPolicy = () => {


    let { PromotionPolicy } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [PromotionPolicy]);

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="static_pages">
                    <div className="mobile_container page_padding">
                        <h5 className="h_font_1"><i>Promotion Policy</i></h5>

                        {/* <h5 className="h_font_1">1. Terms and Conditions of Use Agreement</h5> */}

                        <p className="p_font_New2"><li>All promotions running in Easysettle’s website are subject to each promotion’s terms and conditions.</li></p>
                        <p className="p_font_New2"><li>All promotions run independently and can’t be combined with other ongoing promotions unless otherwise stipulated.</li></p>
                        <p className="p_font_New2"><li>Each discount / voucher / promo code (if any) can only be used once per transaction per account and cannot be combined with other ongoing promotions unless otherwise stipulated.</li></p>
                        <p className="p_font_New2"><li>Each discount / voucher / promo code comes with expiry date and will end by 11.59pm on the specified date.</li></p>
                        <p className="p_font_New2"><li>Easysettle reserves the right to hold or cancel any order which violates any promotion’s terms and conditions.</li></p>

                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment >
    );
};

export default PromotionPolicy;