import React from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import {
    Container,
    Grid,
    TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const RefundPolicy = () => {


    let { RefundPolicy } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [RefundPolicy]);

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="static_pages">
                    <div className="mobile_container page_padding">
                        <h5 className="h_font_1"><i>Refunds Policy</i></h5>

                        {/* <h5 className="h_font_1">Pick Up Service</h5> */}
                        <p className="p_font_New2">
                            Depending on the mode of payment you’ve selected upon transaction, you will be offered the mode of refund based on your payment mode and may be required to enter additional information.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>When will I receive my refund? </span> Depending on the mode of payment you’ve selected upon transaction, you will be offered mode of refund based on your payment mode, which will take between 1 to 15 working days depending on our partnering banks. Please consult the table below for details.
                        </p>
                        <div style={{ overflowX: 'auto' }}>

                            <table className="TableDE">
                                <tr >
                                    <th>How you initially paid for your order</th>
                                    <th>How you may receive your refund</th>
                                    <th>Expected time to transfer funds</th>
                                </tr>
                                <tr>
                                    <td>Credit card, debit card</td>
                                    <td>Credit card/debit card charge reversal (refund)</td>
                                    <td>Up to 15 working days</td>
                                </tr>
                                <tr>
                                    <td>Online banking</td>
                                    <td>Bank transfer</td>
                                    <td>5 - 7 working days</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment >
    );
};

export default RefundPolicy;