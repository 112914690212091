import React from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import {
    Container,
    Grid,
    TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const ReturnExchangePolicy = () => {


    let { ReturnExchangePolicy } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [ReturnExchangePolicy]);

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="static_pages">
                    <div className="mobile_container page_padding">
                        <h5 className="h_font_1"><i>Return / Exchange Policy</i></h5>

                        {/* <h5 className="h_font_1">Pick Up Service</h5> */}
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>What is the return policy?</span> Most orders are returnable within 7 calendar days upon receipt if they are incorrect, damaged or defective.
                        </p>
                        <p className="p_font_New2"><li>
                            Incorrect: The item is not the item you ordered. The model or colour is different from what is indicated on the order summary, or there are missing items or parts inside the packaging.
                        </li></p>
                        <p className="p_font_New2"><li>
                            Damaged: The item is found to be damaged upon receipt.
                        </li></p>
                        <p className="p_font_New2"><li>
                            Defective: The product is not functioning as described by the manufacturer.
                        </li></p>
                        <p className="p_font_New2">
                            Certain items are not eligible to be returned or refunded, such as fridge and washing machine due to certain issues
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Returned items must meet the following requirements:</span>
                        </p>

                        <p className="p_font_New2"><li>
                            The item must be shipped back to us within 7 calendar days upon receipt (as proved by the postal or courier receipt)
                        </li></p>
                        <p className="p_font_New2"><li>
                            Item must be in new condition and returned in its original packaging along with all accessories (including manuals, warranty cards, certificate of authenticity) and free gifts received with it. All packaging must be unused, unmarked and not defaced in any manner.
                        </li></p>
                        <p className="p_font_New2"><li>
                            Item must be unused or hasn’t been installed.
                        </li></p>
                        <p className="p_font_New2"><li>
                            All securely sealed items must not be opened, especially electronic items (unless our service centre can ascertain that they were found defective at the time of opening).
                        </li></p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Will I get a refund in case of returning the item? </span> You will be eligible for a full refund if the item has been inspected and verified that the item was in perfect condition as described above.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Can I return a damaged or defective product after 7 calendar days upon receipt? </span> If the item is covered by Replacement Warranty, you may bring the item to any Easysettle outlet to verify and process the return warranty accordingly.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>How do I return an item? </span> You may email us at <a href="mailto:sinmaaircon@hotmail.com">sinmaaircon@hotmail.com</a> or call us at <a href="https://wa.me/60162991398">+6016 299 1398</a> for any enquiries / questions.
                        </p>

                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment >
    );
};

export default ReturnExchangePolicy;