import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

//@material-ui
import {
  Container,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@material-ui/core";
import { KeyboardArrowRight, Add, Remove } from "@material-ui/icons";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WarrantyRegisterModal from "./WarrantyRegisterModal";
import "./Warranty.css";
import { get_faq, search_warranty } from "../../api/API";

export default function Warranty() {
  const [registerWarranty, setRegisterWarranty] = useState(false);
  const [next, setNext] = useState(false);
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    setRegisterWarranty(false);
    setNext(false);
  };

  const [faq, setFaq] = React.useState([]);

  React.useEffect(() => {
    get_faq().then((json) => {
      setFaq(json.data);
    });
  }, []);

  // handle filter
  const [form, setForm] = useState({
    serial_no: "",
  });
  const [warranty, setWarranty] = useState([]);

  const handleChangeField = (event) => {
    event.persist();
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFilterWarranty = async () => {
    var res = await search_warranty({
      ...form,
    });
    if (res.status) {
      setWarranty(res.data);
      // console.log(res.data[0].product_warranty_id, {...form})
      history.push("/warrantydetail/" + res.product_warranty_id[0], {
        ...form,
      });
    } else {
      alert(res.message);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="c-theLink">
          <Link className="theLink">
            Home
            <KeyboardArrowRight />
            Warranty
          </Link>
        </div>
        <section className="c-warranty">
          <div className="b-warranty-title">
            <h1>Easy Settle Warranty Portal</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incid idunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exerci tation ullamco laboris
              nisi ut aliquip
            </p>
          </div>
          <div className="b-warranty-panel">
            <div className="b-check-warranty">
              <h2>Check Your Warranty</h2>
              <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit, </p>

              <TextField
                className="form-control"
                placeholder="serial number : e.g.xxx 123456"
                name="serial_no"
                value={form.serial_no}
                onChange={handleChangeField}
              />
              <div className="c-btncheckout">
                <Link
                  className="theCheckoutBtn btn-primary"
                  onClick={() => handleFilterWarranty()}
                >
                  Check Your Warranty
                </Link>
              </div>
            </div>
            {/* <div className="b-or">
                  <div className="center-line-left"></div>
                  <div className="center-line"></div>
                <div className="label-or">
                  or
                </div>
              </div> */}
            <div className="b-register-warranty">
              <h2>Register Your Warranty</h2>
              <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit, </p>
              <div className="c-btncheckout">
                <Link
                  className="theCheckoutBtn btn-primary"
                  onClick={() => {
                    setRegisterWarranty(true);
                  }}
                >
                  Register Now
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="c-faq">
          <h2>Warranty FAQs</h2>
          {faq.map((item) => (
            <>
              <Accordion
                square
                className="accordion"
                expanded={expanded === item.faq_id}
                onChange={handleChange(item.faq_id)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded !== item.faq_id ? (
                      <Add htmlColor="black" />
                    ) : (
                      <Remove htmlColor="black" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>{item.faq}</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{item.description}</p>
                </AccordionDetails>
              </Accordion>
            </>
          ))}

          {/* <Accordion square className="accordion" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={expanded !== 'panel2' ? <Add htmlColor='black'/> : <Remove htmlColor='black'/> }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h3>I purchased from a contractor</h3>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                  sit amet blandit leo lobortis eget.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion square className="accordion" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                expandIcon={expanded !== 'panel3' ? <Add htmlColor='black'/> : <Remove htmlColor='black'/> }
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <h3>Where do I find my serial number ?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                  sit amet blandit leo lobortis eget.
                </p>
              </AccordionDetails>
            </Accordion> */}
        </section>
      </Container>
      <Footer />
      <WarrantyRegisterModal
        open={registerWarranty}
        next={next}
        setNext={setNext}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
}
