import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

//@material-ui
import {
  Container,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { KeyboardArrowRight, Add, Remove } from "@material-ui/icons";

//image
import splitAc from "../../assets/images/split-ac.png";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./WarrantyDetail.css";

import { get_faq, search_warranty } from "../../api/API";

export default function WarrantyDetail(props) {

  let WarrantyDetail = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [WarrantyDetail]);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //FAQ  Warranty Detail

  const [faq, setFaq] = React.useState([]);

  React.useEffect(() => {
    get_faq().then((json) => {
      setFaq(json.data);
    });
  }, []);

  // handle filter
  const [form, setForm] = useState({
    // serial_no: location.state.serial_no
    serial_no: ""
  });
  const [warranty, setWarranty] = useState([]);

  const handleChangeField = (event) => {
    event.persist();
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFilterWarranty = async () => {
    var res = await search_warranty({
      ...form,
    });
    if (res.status) {
      setWarranty(res.data);
    }
  };

  React.useEffect(async () => {
    if (WarrantyDetail.state.serial_no) {
      var res = await search_warranty({
        ...form,
        serial_no: WarrantyDetail.state.serial_no,
      });
      if (res.status) {
        setWarranty(res.data);
      }
    } else {
      var res = await search_warranty({
        ...form,
        serial_no: WarrantyDetail.state.serial_no,
      });
      if (res.status) {
        setWarranty(res.data);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="c-theLink">
          <Link className="theLink">
            Home
            <KeyboardArrowRight />
            Warranty
          </Link>
        </div>
        {/* <section className="c-warranty-detail">
            <Grid container justify="center">
              <Grid container className="c-search" alignItems="center">
                <Grid item xs={12} sm={8} md={10}>
                  <TextField
                    className="form-control"
                    placeholder="xxx 123456"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <div className="c-btncheckout">
                    <div className="theCheckoutBtn btn-primary" style={{display:'flex', alignItems:'center', height:'34px' }}>Search Another</div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={5} className="c-product">
                <div item className='b-product'>
                  <br/>
                  <div className="product">
                    <div className="c-image">
                      <img src={splitAc}/>
                    </div>
                    <h3 style={{margin:'20px 0'}}>HISENSE ECOSAVING AIRCON XXX123</h3>
                  </div>
                  <div className="c-description">
                    <h3>Model Number</h3>
                    <p>xxx123</p>
                  </div>
                  <div container className="c-description">
                    <h3>Brand</h3>
                    <p>Hisense</p>
                  </div>
                  <div container className="c-description">
                    <h3>H.P</h3>
                    <p>1.5</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={7} className="c-warranty-card">
                <h4>Your Warranty Card</h4>
                <div className="b-warranty-card">
                  <div className="c-description">
                    <h3>Product Model Number</h3>
                    <p>xxx123</p>
                  </div>
                  <div className="c-description">
                    <h3>Date of Purchase</h3>
                    <p>12 December 1920</p>
                  </div>
                  <div className="c-description">
                    <h3>Valid Unitl</h3>
                    <p>20 December 2099</p>
                  </div>
                  <div className="c-description">
                    <h3>Address</h3>
                    <p>51, Jalan ABC,<br/> Taman ABC <br/> 88000 <br/> Johor Bahru <br/> Johor</p>
                  </div>
                  <p style={{marginTop:'20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid idunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci tation ullamco laboris nisi ut aliquip</p>
                </div>
              </Grid>
            </Grid>
          </section> */}
        <div className="c-theSearch">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={9}>
              <TextField
                className="form-control"
                placeholder="xxx 123456"
                name="serial_no"
                value={form.serial_no}
                onChange={handleChangeField}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Link
                className="btn-primary theSeatchBtn"
                onClick={() => handleFilterWarranty()}
              >
                Search Another
              </Link>
            </Grid>
          </Grid>
        </div>
        {warranty.map((item) => (
          <div className="c-theWarrantyProduct">
            <Grid container spacing={3} alignItems="flex-center">
              <Grid item xs={12} sm={12} md={5}>
                <div className="c-theProduct">
                  <div className="theProductImg">
                    <img src={item.image} />
                  </div>
                  <div className="theProductName">
                    <h3>{item.product_name}</h3>
                  </div>
                  <div className="theProductDetail">
                    <div className="theFlex">
                      <p className="bold">Model Number</p>
                      <p>xxx123</p>
                    </div>
                    <div className="theFlex">
                      <p className="bold">Brand</p>
                      <p>{item.brand_name}</p>
                    </div>
                    <div className="theFlex">
                      <p className="bold">H.P</p>
                      <p>1.5</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div className="c-theWarrantyCard">
                  <h3>Your Warranty Card</h3>
                  <div className="c-theWarrantyDetail">
                    <div className="theFlex">
                      <p class="bold">Product Model Number</p>
                      <p>xxx 123</p>
                    </div>
                    <div className="theFlex">
                      <p class="bold">Date of Purchase</p>
                      <p>{item.purchase_date}</p>
                    </div>
                    <div className="theFlex">
                      <p class="bold">Valid Until</p>
                      <p>20 December 2099</p>
                    </div>
                    <div className="theFlex">
                      <p class="bold">Address</p>
                      <p>
                        {item.address1} {item.address2} {item.postcode}{" "}
                        {item.state}
                      </p>
                    </div>
                    <p style={{ color: "#6f6f6f", marginTop: "2rem" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ))}

        <div className="c-faq">
          <h2>Warranty FAQs</h2>
          {faq.map((item) => (
            <>
              <Accordion
                square
                className="accordion"
                expanded={expanded === item.faq_id}
                onChange={handleChange(item.faq_id)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded !== item.faq_id ? (
                      <Add htmlColor="black" />
                    ) : (
                      <Remove htmlColor="black" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h3>{item.faq}</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{item.description}</p>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
