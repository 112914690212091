import React from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import {
    Container,
    Grid,
    TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const ShippingDelivery = () => {


    let { ShippingDelivery } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [ShippingDelivery]);

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="static_pages">
                    <div className="mobile_container page_padding">
                        <h5 className="h_font_1"><i>Shipping & Delivery</i></h5>

                        <h5 className="h_font_1">Pick Up Service</h5>

                        <p className="p_font_New2"><li>
                            Pick up will be available after a second email will be sent to you shortly after (excluding weekend & public holidays) where your order will be processed and dispatched from our warehouse to the selected pick up point during online checkout. This may take longer during sales period.
                        </li></p>
                        <p className="p_font_New2"><li>
                            Majority of the product are entitled for pick up services, except for big items.
                        </li></p>
                        <p className="p_font_New2"><li>
                            Pick up will be available at the selected locations from 5pm onwards.
                        </li></p>
                        <p className="p_font_New2"><li>
                            In the event that you are not available to collect your order on the assigned date, please whatsapp <a href="https://wa.me/601155597917">011-5559 7917</a> or email to <a href="mailto:sinmaaircon@hotmail.com"> sinmaaircon@hotmail.com</a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            🏬 马赛城Taman Kota Masai, Pasir Gudang
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            14, Jalan Ekoperniagaan 2, 81700 Pasir Gudang.
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href="https://waze.com/ul/hw23bmjuj2">📍Waze: https://waze.com/ul/hw23bmjuj2</a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href=" https://goo.gl/maps/5PEVsNosR4jakhDU7">📍Google:  https://goo.gl/maps/5PEVsNosR4jakhDU7</a>
                        </li></p>
                        <p className="p_font_New2" style={{ marginTop: 0 }}><li>
                            <a href="https://wa.me/601155087197">📲CS 3: wasap.my/601155087197</a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            🏬 士古来 Bizhub 8, Skudai
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            28, Jalan Persiaran Skudai 8, 81300 Skudai.
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href="https://waze.com/ul/hw238xq79m">📍Waze: https://waze.com/ul/hw238xq79m</a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href="https://goo.gl/maps/Nmsr5zJGuT7n7fhU9">📍Google: https://goo.gl/maps/Nmsr5zJGuT7n7fhU9</a>
                        </li></p>
                        <p className="p_font_New2" style={{ marginTop: 0 }}><li>
                            <a href="https://wa.me/601110737197">📲CS 3: wasap.my/601110737197</a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            🏬 新山Taman Johor Jaya, JB
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            28-30, Jalan Dedap 4, Taman Johor Jaya, 81100 Johor Bahru.
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href="https://waze.com/ul/hw23bf07zp">📍Waze: https://waze.com/ul/hw23bf07zp</a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href="https://goo.gl/maps/4JqYyzVrinR5rzpg8">📍Google: https://goo.gl/maps/4JqYyzVrinR5rzpg8</a>
                        </li></p>
                        <p className="p_font_New2" style={{ marginTop: 0 }}><li>
                            <a href="https://wa.me/60182259711">📲CS 3: wasap.my/60182259711</a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            🏬 古来Bandar Indahpura, Kulai
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            235 & 236, Jalan Kenanga 29/8, Bandar Indahpura, 81000 Kulai.
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href="https://waze.com/ul/hw239mfgzq ">📍Waze: https://waze.com/ul/hw239mfgzq </a>
                        </li></p>
                        <p className="p_font_New2" style={{ margin: 0 }}><li>
                            <a href="https://goo.gl/maps/q17raqGKhHPTzeCk6">📍Google: https://goo.gl/maps/q17raqGKhHPTzeCk6</a>
                        </li></p>
                        <p className="p_font_New2" style={{ marginTop: 0 }}><li>
                            <a href="https://wa.me/601155597197">📲CS 3: wasap.my/601155597197</a>
                        </li></p>

                        <h5 className="h_font_1">Standard Delivery</h5>
                        <p className="p_font_New2"><li>
                            Delivery will take from 7 to 14 working days upon confirmation of order (excluding weekend & public holidays) where your order will be processed and dispatched from our warehouse. Please note that this may take longer during sales period.
                        </li></p>
                        <p className="p_font_New2"><li>
                            Applicable to selected items on the website only.
                        </li></p>
                        <p className="p_font_New2"><li>
                            Basic installation will be provided without charges for purchases of bigger items, unless otherwise mentioned.
                        </li></p>
                        <p className="p_font_New2"><li>
                            A signature is required upon receiving your order as a proof of receipt.
                        </li></p>
                        <p className="p_font_New2"><li>
                            In the event that you are not available to collect your order on the assigned date, please call <a href="https://wa.me/601110737197">601110737197</a> or email to  <a href="mailto:sinmaaircon@hotmail.com"> sinmaaircon@hotmail.com</a>
                        </li></p>
                        <p className="p_font_New2"><li>
                            Service rates for delivery to regular service area (RSA)
                        </li></p>

                        <div style={{ overflowX: 'auto' }}>

                            <table className="TableDE">
                                <thead>
                                    <tr>
                                        <th class="tg-0pky" rowspan="2">RSA DEstinations</th>
                                        <th class="tg-0lax" colspan="4">Parcel</th>
                                    </tr>
                                    <tr>
                                        <th class="tg-0lax">1st Break</th>
                                        <th class="tg-0lax">Price</th>
                                        <th class="tg-0lax">Sub Break</th>
                                        <th class="tg-0lax">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="tg-0lax">Local (LOC)</td>
                                        <td class="tg-0lax">10.0kg</td>
                                        <td class="tg-0lax">11.00</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">0.60</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-0lax">Klang Valley (KLC)</td>
                                        <td class="tg-0lax">10.0kg</td>
                                        <td class="tg-0lax">15.00</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">1.20</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-0lax">Pen Malaysia (PMS)</td>
                                        <td class="tg-0lax">10.0kg</td>
                                        <td class="tg-0lax">15.00</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">1.20</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-0lax">Kuching (KCH)</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">10.00</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">8.00</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-0lax">Sarawak other tthan KCH (SWK)</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">13.00</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">10.00</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-0lax">Kota Kinabalu (BKI)</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">11.00</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">9.00</td>
                                    </tr>
                                    <tr>
                                        <td class="tg-0lax">Sabah &amp; Labuan other than BKI (SBH)</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">13.00</td>
                                        <td class="tg-0lax">1.0kg</td>
                                        <td class="tg-0lax">10.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment >
    );
};

export default ShippingDelivery;