import React from "react";
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Link, useLocation } from "react-router-dom";
import services from "../../assets/images/chemical.png";
import map from "../../assets/images/map.png";

import "./JobList.css";
import { Room, Today } from "@material-ui/icons";

export default function JobList() {
  let { JobList } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [JobList]);

  const [time, setTime] = React.useState("");
  const handleChangeTime = (event) => {
    setTime(event.target.value);
  };
  const [type, setType] = React.useState("");
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theJobListSection">
          <div className="theFilterHeader">
            <div className="theForm">
              <div className="theFormGroup">
                <TextField className="theFormControl" placeholder="Area" />
              </div>
              <div className="theFormGroup">
                <FormControl className="theFormControl">
                  <Select
                    value={time}
                    onChange={handleChangeTime}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} disabled>
                      Select Time
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="theFormGroup">
                <FormControl className="theFormControl">
                  <Select
                    value={type}
                    onChange={handleChangeType}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={""} disabled>
                      Job Type
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="theFormGroup">
                <Link className="btnSearch">Search</Link>
              </div>
            </div>
          </div>
          <div className="theFilterHeaderMob">
            <div className="theForm">
              <div className="theFormGroup">
                <TextField className="theFormControl" placeholder="Area" />
              </div>
              <div className="theFlexMobile">
                <div className="theFormGroupFlex">
                  <FormControl className="theFormControl">
                    <Select
                      value={time}
                      onChange={handleChangeTime}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={""} disabled>
                        Select Time
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="theFormGroupFlex">
                  <FormControl className="theFormControl">
                    <Select
                      value={type}
                      onChange={handleChangeType}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={""} disabled>
                        Job Type
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="theFormGroup">
                <Link className="btnSearch">Search</Link>
              </div>
            </div>
          </div>
          <div className="theJobArea">
            <div className="theJobHeader">
              <p>
                Found 10 Job Near <span>Area Name</span>
              </p>
            </div>
            <div className="theJobListing">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3} md={3}>
                  <Link className="theCard"  to="/jobdetail">
                    <div className="theServiceIMG">
                      <img src={services} />
                    </div>
                    <div className="theDetail">
                      <div className="theFlex">
                        <Room className="theIcon" />
                        <p className="area">Area Name</p>
                      </div>
                      <p className="service">Chemical Maintanance Service</p>
                      <div className="theFlex_">
                        <Today className="theIcon" />
                        <p>Time</p>
                      </div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Link className="theCard">
                    <div className="theServiceIMG">
                      <img src={services} />
                    </div>
                    <div className="theDetail">
                      <div className="theFlex">
                        <Room className="theIcon" />
                        <p className="area">Area Name</p>
                      </div>
                      <p className="service">Chemical Maintanance Service</p>
                      <div className="theFlex_">
                        <Today className="theIcon" />
                        <p>Time</p>
                      </div>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
