import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import mastercard from "../../assets/images/mastercard.png";
import maybank from "../../assets/images/maybank.png";
import paypal from "../../assets/images/paypal.png";
import rhb from "../../assets/images/rhb.png";
import visa from "../../assets/images/visa.png";
import alliance from "../../assets/images/alliance.png";
import ambank from "../../assets/images/ambank.jpg";
import cimb from "../../assets/images/cimb.png";
import fpx from "../../assets/images/fpx.png";
import hongleong from "../../assets/images/hongleong.png";
import footerimg from "../../assets/images/banner_footer.png";
import "./Footer.css";
import brand from "../../assets/images/toshiba.png";
import Slider from "react-slick";

import { get_brand, get_category } from "../../api/API";
import Category from "../../pages/Category/Category";

export default function Footer() {
  const [brand, setBrand] = React.useState([]);
  const [category, setCategory] = React.useState([]);

  const getData = async () => {
    var resCategory = await get_category({});
    var temp_data = []
    if (resCategory.status) {
      console.log(resCategory.data.length)

      for (var i = 0; i < 8; i++) {
        console.log(resCategory.data[i])
        temp_data.push(resCategory.data[i])
      }
      setCategory(temp_data)
      // setAllCategory(resCategory.data)
    }
    var resBrand = await get_brand({});
    if (resBrand.status) {
      setBrand(resBrand.data);
    }
  }

  React.useEffect(() => {
    getData();
    // get_brand().then((json) => {
    //   setBrand(json.data);
    // });
    // get_category().then((json) => {
    //   setCategory(json.data);
    // });
  }, []);

  //slider
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          // infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4,
          // infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          // infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="c-footer">
        <Container>
          {/* <div className="c-brandslider-footer">
            <h3>Browse By Brands</h3>
            <div className="c-slider">
              <Slider {...settings}>
                {brand.map((item) => (
                  <div>
                    <div className="c-theSliderBox">
                      <img src={item.brand_image} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div> */}
          <br />
          <br />
          <div className="footer-banner">
            <img src={footerimg}></img>
            <h4 className="footer-banner-header">YOUR ONE STOP PLACE FOR YOUR HOME</h4>
            <h4 className="footer-banner-body">No.1 Largest Aircond, Water Heater, Fan Distributor in Malaysia. Sell more than 10,000 units per month</h4>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4} md={3}>
              <div className="navbar-footernav">
                <h4>Category List</h4>
                {category.map((item) => (
                  <Link to={`../category/1`} className="nav-footerlink">{item.name}</Link>
                ))}

                {/* <Link className="nav-footerlink">Equipment & Tools</Link>
                <Link className="nav-footerlink">Kitchen Appliance</Link>
                <Link className="nav-footerlink">Material & Accessories</Link>
                <Link className="nav-footerlink">Services</Link> */}
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <div className="navbar-footernav">
                <h4>FAQ</h4>
                {/* <Link className="nav-footerlink">Installation Guide</Link> */}
                <Link className="nav-footerlink" to="/term_and_condition">Terms & Conditions</Link>
                <Link className="nav-footerlink" to="/privacy_policy">Privacy Policy</Link>
                <Link className="nav-footerlink" to="/promotion_policy">Promotion Policy</Link>
                <Link className="nav-footerlink" to="/shipping_and_delivery">Shipping & Delivery</Link>
                <Link className="nav-footerlink" to="/cancellation_policy">Cancellation Policy</Link>
                <Link className="nav-footerlink" to="/return_exchange_policy">Return/Exchange Policy</Link>
                <Link className="nav-footerlink" to="/refund_policy">Refund Policy</Link>
                <Link className="nav-footerlink" to="/admin_fee">Admin Fee</Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <div className="navbar-footernav">
                <h4>Contact Us</h4>
                <Link className="nav-footerlink">
                  <p>Email:</p>easysettle@hotmail.com
                </Link>
                <Link className="nav-footerlink">
                  <p>Customer Support:</p>+6011-1122-8197
                </Link>
                <Link className="nav-footerlink">
                  <p>Customer Support:</p>+6011-5559-7197
                </Link>
                <Link className="nav-footerlink">
                  <p>Address:</p>NO 28&30, Jalan Dedap 4,<br /> Taman Johor Jaya, <br /> 81100 Johor Bahru, <br /> Johor.
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="navbar-footernav-payment">
                <h4 className="payment-header">Payment Method</h4>
                <Grid container spacing={0}>
                  <Grid item xs={12} className="paymentGridFlex">
                    <Link className="nav-footerlink-icon">
                      <img src={mastercard}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={maybank}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={paypal}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={rhb}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={visa}></img>
                    </Link>
                  </Grid>
                  <Grid item xs={12} className="paymentGridFlex">
                    <Link className="nav-footerlink-icon">
                      <img src={alliance}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={ambank}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={cimb}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={fpx}></img>
                    </Link>
                    <Link className="nav-footerlink-icon">
                      <img src={hongleong}></img>
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}