import React from "react";
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./ReferFriend.css";

import avatar from "../../assets/images/avantar.png";
import coin from "../../assets/images/coin.svg";
import point from "../../assets/images/point.svg";
import refer from "../../assets/images/refer.png";
import { FileCopy } from "@material-ui/icons";

export default function ReferFriend() {
  const [day, setDay] = React.useState("");
  const handleChangeDay = (event) => {
    setDay(event.target.value);
  };
  const [tab, setTab] = React.useState("Latest");
  const handleChangeTab = (text) => {
    setTab(text);
  };
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theReferSection">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={3}>
              <div className="theUserCard">
                <div className="theCardHeader">
                  <div className="theAvatar">
                    {/* <img src={avatar} /> */}
                    <p>Name</p>
                  </div>
                </div>
                <div className="theCardBody">
                  <Link className="theCoin" to="/coinreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={coin} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Coins</p>
                        <p className="coin">9,000 Coins</p>
                      </div>
                    </div>
                  </Link>
                  {/* <Link className="theCoin" to="/pointreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={point} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Points</p>
                        <p className="point">9,000 Points</p>
                      </div>
                    </div>
                  </Link> */}
                  <div class="theLink">
                    <Link className="theNavLink" to="/profile">
                      My Profile
                    </Link>
                    <Link className="theNavLink" to="/address">
                      Address
                    </Link>
                    <Link className="theNavLink" to="/order">
                      Order History
                    </Link>
                    <Link className="theNavLinkActive" to="/referfriend">
                      Refer a Friend
                    </Link>
                    <Link className="theNavLink" to="/becomeconstructor">
                      Become Constructor
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <div className="theCardCon">
                <div className="theCardConHeader">
                  <p>Refer a Friend</p>
                </div>
                <div className="theCardConBody">
                  <div className="theFlexBox">
                    <div className="theIMG">
                      <img src={refer} />
                    </div>
                    <div className="theDetail">
                      <p>Share your referral code</p>
                      <div className="theBtn">
                        <Link className="theCopyBtn">
                          <div className="theFlex">
                            <p>EXPEC227</p>
                            <FileCopy />
                          </div>
                        </Link>
                      </div>
                      <div className="theBtn">
                        <Link className="theShareBtn">Share Now</Link>
                      </div>
                      <div className="theFlexRow">
                        <Link className="theText">How to invite</Link>
                        <Link className="theText">Terms & Conditions</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <div className="theTransaction">
            <div className="theCardCon">
              <div className="theCardConHeader">
                <p>Transactions</p>
              </div>
              <div className="theCardConBody">
                <div className="theSubHeader">
                  <div className="theTabList">
                    <Link className={tab === "Latest" ? "theLastestActive" : "theLastest"} onClick={()=>handleChangeTab("Latest")}>Latest</Link>
                    <Link className={tab === "Archived" ? "theArchivedActive" : "theArchived"} onClick={()=>handleChangeTab("Archived")}>Archived</Link>
                  </div>
                  <div className="theFilterDay">
                    <FormControl className="">
                      <Select
                        value={day}
                        onChange={handleChangeDay}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Last 7 days"}>Last 7 days</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="theTransactionList">
                  {tab === "Latest" && (
                    <>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {tab === "Archived" && (
                    <>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                      <div className="theList">
                        <div className="thePadd">
                          <div className="theFlex">
                            <p className="service">Service Fee</p>
                            <p className="price">+RM 9,000.00</p>
                          </div>
                          <div className="theFlex">
                            <p className="order">Order no. 123456789</p>
                            <p className="date">22 Apr</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
