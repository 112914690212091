import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  // return
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.cart.length > 0 ? cartItems : [])
  );
  console.log('storage cartItems', cartItems, cartItems.cart.length)
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);
  const getVoucher = () => {
    console.log(cart.voucher, 'cart.voucher')
    if (cart.voucher != '' && cart.voucher != undefined) {
      console.log('getVoucherarray')
      return cart.voucher;
    } else {
      console.log('getVoucher false')
      return false;
    }
  }
  const getVoucherValue = () => {
    if (cart.voucher_value) {
      return cart.voucher_value;
    }
    return false;
  }

  const setVoucher = (voucher) => {
    if (cart.cart != undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    console.log('setvoucher usecart', cart)
    var existed = 0;
    var index = 0;
    // var filtercartTemp = cartTemp.filter();
    for (var i = 0; i < cartTemp.length; i++) {
      if (cartTemp[i].quantity < 1) {
        existed = 1;
        index = i;
        cartTemp.splice(i, 1);
      }
    }
    setCart({
      ...cart,
      cart: cartTemp,
      voucher: voucher.voucher,
      voucher_value: voucher.voucher_value
    });
    // setCart({
    // 	...cart,
    // 	voucher: voucher.voucher,
    // 	voucher_value: voucher.voucher_value
    // });
  }

  function clearVoucher() {
    if (cart.cart != undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    var existed = 0;
    var index = 0;
    // var filtercartTemp = cartTemp.filter();
    for (var i = 0; i < cartTemp.length; i++) {
      if (cartTemp[i].quantity < 1) {
        existed = 1;
        index = i;
        cartTemp.splice(i, 1);
      }
    }
    // console.log('existed', existed, index)
    // console.log('cancel voucher function', cartTemp)
    setCart({
      ...cart,
      cart: cartTemp,
      voucher: [],
      voucher_value: []
    });
    // Storage(cartTemp);
  }

  function getCart() {
    // console.log(cart)
    if (cart.cart) {
      // console.log('getCart cart', cart)
      return cart.cart;
    }
    return cart;
  }

  function getDeliveryCart() {
    if (cart.cart) {
      var cartTemp = [...cart.cart];
      var cartInstallation = [];

      cartTemp.map(data => {
        if (data.is_installation == 1) {
          cartInstallation.push(data);
        }
      });
      return cartInstallation;
    }
    return [];
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    setCart(cart);
  }

  function addProduct(item) {
    if (cart.cart != undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    var newItem = { ...item, check: true };
    newItem.total_cash_rate = (getPriceByQuantity(newItem, newItem.quantity).cash_rate).toFixed(2);
    newItem.total_online_payment_rate = (getPriceByQuantity(newItem, newItem.quantity).online_payment_rate).toFixed(2);
    newItem.total_installment_rate = (getPriceByQuantity(newItem, newItem.quantity).installment_rate).toFixed(2);
    newItem.total_installment_rate_2 = (getPriceByQuantity(newItem, newItem.quantity).installment_rate_2).toFixed(2);

    if (cartTemp.length === 0) {
      newItem["index"] = 0;
      cartTemp.push(newItem);
    } else {
      var existed = 0;
      var index = 0;
      for (var i = 0; i < cartTemp.length; i++) {
        if (
          cartTemp[i].variant_id == newItem.variant_id &&
          cartTemp[i].product_variation_id == newItem.product_variation_id &&
          cartTemp[i].product_id == newItem.product_id
          // cartTemp[i].price_type_id == newItem.price_type_id &&
          // cartTemp[i].cash_rate == newItem.cash_rate &&
          // cartTemp[i].online_payment_rate == newItem.online_payment_rate &&
          // cartTemp[i].installment_rate == newItem.installment_rate &&
          // cartTemp[i].installment_rate_2 == newItem.installment_rate_2
        ) {
          existed = 1;
          index = i;
        }
      }

      if (existed == 1) {
        if (newItem.add_on != undefined) {
          cartTemp[index]['add_on'] = newItem.add_on;
        }
        var new_quantity =
          parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);
        cartTemp[index]["quantity"] = new_quantity;

        cartTemp[index]["total_cash_rate"] = (getPriceByQuantity(
          cartTemp[index],
          new_quantity,
        ).cash_rate).toFixed(2);

        cartTemp[index]["total_online_payment_rate"] = (getPriceByQuantity(
          cartTemp[index],
          new_quantity,
        ).online_payment_rate).toFixed(2);

        cartTemp[index]["total_installment_rate"] = (getPriceByQuantity(
          cartTemp[index],
          new_quantity,
        ).installment_rate.toFixed(2));

        cartTemp[index]["total_installment_rate_2"] = (getPriceByQuantity(
          cartTemp[index],
          new_quantity,
        ).installment_rate_2).toFixed(2);

      } else {
        newItem["index"] = cartTemp.length;
        cartTemp.push(newItem);
      }
    }
    setCart(cartTemp);

    setCart({
      ...cart,
      cart: cartTemp,
    });
    Storage({
      ...cart,
      cart: cartTemp,
    });
    window.localStorage.setItem("length", cartTemp.length);
    console.log('addporduct cartTemp', cartTemp);
  }

  // function increaseProduct(index) {
  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   var quantity = parseFloat(cartTemp[index].quantity) + 1;
  //   cartTemp[index]["quantity"] = quantity;
  //   cartTemp[index]["price"] = parseFloat(
  //     getPriceByQuantity(cartTemp[index], quantity)
  //   );
  //   setCart(cartTemp);
  //   Storage(cartTemp);


  //   // console.log(cartTemp);
  // }

  function increaseProduct(index) {
    var cartTemp = [...cart.cart];
    var quantity = parseFloat(cartTemp[index].quantity) + 1;
    console.log('cartTemp[index].quantity', cartTemp[index].quantity, 'quantity', quantity, cartTemp)
    cartTemp[index]["quantity"] = quantity;

    cartTemp[index]["total_cash_rate"] = (getPriceByQuantity(
      cartTemp[index],
      quantity,
    ).cash_rate).toFixed(2);

    cartTemp[index]["total_online_payment_rate"] = (getPriceByQuantity(
      cartTemp[index],
      quantity,
    ).online_payment_rate).toFixed(2);

    cartTemp[index]["total_installment_rate"] = (getPriceByQuantity(
      cartTemp[index],
      quantity,
    ).installment_rate.toFixed(2));

    cartTemp[index]["total_installment_rate_2"] = (getPriceByQuantity(
      cartTemp[index],
      quantity,
    ).installment_rate_2).toFixed(2);

    setCart({
      ...cart,
      cart: cartTemp,
    });
    Storage({
      ...cart,
      cart: cartTemp,
    });
    console.log('increaseProduct', cartTemp);
  }

  function decreaseProduct(index) {
    var cartTemp = [...cart.cart];
    var quantity = parseFloat(cartTemp[index].quantity) - 1;
    console.log('cartTemp[index].quantity', cartTemp[index].quantity, 'quantity', quantity, cartTemp)
    if (quantity > 0) {
      cartTemp[index]["quantity"] = quantity;

      cartTemp[index]["total_cash_rate"] = (getPriceByQuantity(
        cartTemp[index],
        quantity,
      ).cash_rate).toFixed(2);
  
      cartTemp[index]["total_online_payment_rate"] = (getPriceByQuantity(
        cartTemp[index],
        quantity,
      ).online_payment_rate).toFixed(2);
  
      cartTemp[index]["total_installment_rate"] = (getPriceByQuantity(
        cartTemp[index],
        quantity,
      ).installment_rate.toFixed(2));
  
      cartTemp[index]["total_installment_rate_2"] = (getPriceByQuantity(
        cartTemp[index],
        quantity,
      ).installment_rate_2).toFixed(2);
    } else {
      cartTemp.splice(index, 1);
      // window.localStorage.setItem('length', cartTemp.length - 1)
      for (var i = 0; i < cartTemp.length; i++) {
        cartTemp[i]["index"] = i;
      }
    }

    setCart({
      ...cart,
      cart: cartTemp,
    });
    Storage({
      ...cart,
      cart: cartTemp,
    });
    console.log('decreaseProduct', cartTemp);
  }

  function getPriceByQuantity(cartRowItem, qty) {
    return {
      cash_rate: cartRowItem.cash_rate * qty,
      online_payment_rate: cartRowItem.online_payment_rate * qty,
      installment_rate: cartRowItem.installment_rate * qty / 12,
      installment_rate_2: cartRowItem.installment_rate_2 * qty / 24
    }
  }

  // function decreaseProduct(index) {
  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   var quantity = parseFloat(cartTemp[index].quantity) - 1;

  //   if (quantity > 0) {
  //     cartTemp[index]["quantity"] = quantity;
  //     cartTemp[index]["price"] = getPriceByQuantity(cartTemp[index], quantity);
  //   } else {
  //     cartTemp.splice(index, 1);
  //     for (var i = 0; i < cartTemp.length; i++) {
  //       cartTemp[i]["index"] = i;
  //     }
  //   }

  //   setCart(cartTemp);
  //   Storage(cartTemp);

  //   // console.log(cartTemp);
  // }

  function getTotalCart() {
    var cartTemp = [...cart.cart];
    var total_cart_price = 0;

    for (var i = 0; i < cartTemp.length; i++) {
      total_cart_price +=
        parseFloat(cartTemp[i].price) * parseFloat(cartTemp[i].quantity);
    }
    return total_cart_price;
  }

  function removeProduct(index) {
    var cartTemp = [...cart.cart];
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]["index"] = i;
    }

    setCart({
      ...cart,
      cart: cartTemp,
    });
    Storage({
      ...cart,
      cart: cartTemp,
    });
  }

  return {
    getVoucher,
    getVoucherValue,
    setVoucher,
    clearVoucher,
    addProduct,
    clearCart,
    getTotalCart,
    removeProduct,
    getCart,
    getDeliveryCart,
    increaseProduct,
    decreaseProduct,
    setCartFunction,
  };
};

export default useCart;
