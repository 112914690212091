import React from "react";
import {
  AppBar,
  Container,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useHistory, withRouter } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SearchIcon from "@material-ui/icons/Search";
import "./Header.css";
import {
  get_category,
  get_profile,
  get_user_wallet
} from "../../api/API";

import logo from "../../assets/images/easysettle.png";
import love from "../../assets/images/love.png";
import cart1 from "../../assets/images/carts.png";
import avatar from "../../assets/images/avantar.png";
import coin from "../../assets/images/coin.svg";
import point from "../../assets/images/point.svg";
import useCart from "../../hooks/useCart";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
}));

function Header(props) {
  const history = useHistory();
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = React.useState({ left: false });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [anchorElAll, setAnchorElAll] = React.useState(null);
  const openAll = Boolean(anchorElAll);

  const handleClickAll = (event) => {
    setAnchorElAll(event.currentTarget);
  };

  const handleCloseAll = () => {
    setAnchorElAll(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);

  const [openList, setOpenList] = React.useState(0);

  const handleClickNested = (index) => {
    setOpenList(index);
  };

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (index, event) => {
    setAnchorEl2({ [index]: event.currentTarget });
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [token, setToken] = React.useState([]);
  // console.log(token)
  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav">
        <div className="c-sidebarlist">
          <div className="c-logo">
            <Link to="/">
              <img src={logo}></img>
            </Link>
          </div>
        </div>
      </List>
      {/* <Link className="btnlogout">Logout</Link> */}
    </div>
  );

  //PointCoin
  const [walletData, seWalletData] = React.useState(null);
  //category
  const [category, setCategory] = React.useState(null);
  const [allcategory, setAllCategory] = React.useState(null);
  //Profile
  const [user, setUser] = React.useState([]);

  const getData = async () => {
    var resCategory = await get_category({});
    var temp_data = []
    if (resCategory.status) {
      console.log(resCategory.data.length)

      for (var i = 0; i < 7; i++) {
        console.log(resCategory.data[i])
        temp_data.push(resCategory.data[i])
      }
      setCategory(temp_data)
      setAllCategory(resCategory.data)
    }

    if (token) {
      var resProfile = await get_profile({});
      if (resProfile.status) {
        setUser(resProfile.data[0]);
      }
      var resWallet = await get_user_wallet({});
      if (resWallet.status) {
        seWalletData(resWallet.data)
      }
    }
  }
  // console.log('category', category)

  React.useEffect(() => {
    getData()
  }, []);

  const handleMenu = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user_id");
    window.localStorage.removeItem("length");
    // window.localStorage.removeItem("cart");
    history.push("/");
    window.location.reload();
  };

  const { getCart } = useCart();

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  return (
    <>
      <div className="c-stickyheader">
        <div className="c-stickylist">
          <div className="none-mobile">
            <Link className="nav-link">Free Support & 1 Year Warranty</Link>
            <Link className="nav-link">Free Shipping*</Link>
            <Link className="nav-link_" to="/services">Servicing and Maintenance</Link>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {token ? (
              <Link className="nav-link" to="/order">
                Track Your Order
              </Link>
            ) : (
              null
              // <Link className="nav-link" to="/login">
              //   Track Your Order
              // </Link>
            )}

            {/* <Link className="nav-link_" to="/signup">
              Username
            </Link> */}

            {token ? (
              <>
                <div className="theDropdown">
                  <div className="nav-link_" onClick={handleMenu}>
                    <p>{user.name}</p>
                  </div>
                  <Menu
                    id="menu-appbar"
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorEl={anchorEl2}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    anchorPosition={{
                      top: 200,
                    }}
                    keepMounted
                    open={open2}
                    onClose={handleClose2}
                  >
                    <MenuItem>
                      <div className="theUserDetail">
                        {/* <div className="theUserProfile">
                          <img src={avatar} />
                        </div> */}
                        <div className="theCoinPoint">
                          <div className="theCoin">
                            <div className="theFlex">
                              <div className="theIconIMG">
                                <img src={coin} />
                              </div>
                              <div className="theCoinDetail">
                                <p className="title">Easy Coins</p>
                                <p className="coin">{walletData != null && walletData.coin_balance} Coins</p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="theCoin">
                            <div className="theFlex">
                              <div className="theIconIMG">
                                <img src={point} />
                              </div>
                              <div className="theCoinDetail">
                                <p className="title">Easy Points</p>
                                <p className="point">9,000 Points</p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <Link className="theDropdownLink" to="/coinreward">
                        Coin Reward
                      </Link>
                    </MenuItem>
                    {/* <MenuItem>
                      <Link className="theDropdownLink" to="/pointreward">
                        Point Reward
                      </Link>
                    </MenuItem> */}
                    <MenuItem>
                      <Link className="theDropdownLink" to="/order">
                        Order
                      </Link>
                    </MenuItem>
                    {/* <MenuItem>
                      <Link className="theDropdownLink" to="/">
                        Wishlist
                      </Link>
                    </MenuItem> */}
                    <MenuItem>
                      <Link className="theDropdownLink" to="/profile">
                        Account
                      </Link>
                    </MenuItem>

                    <div style={{ borderTop: "1px solid #eaeaea" }}>
                      <MenuItem>
                        <a className="theDropdownLink" onClick={() => handleLogout()}>
                          Logout
                        </a>
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              </>
            ) : (
              <Link className="nav-link_" to="/login">
                Register or Sign In
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="c-header">
        <Container maxWidth="lg">
          <AppBar position="static">
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer("left", true)}
                  >
                    <MenuIcon style={{ color: "#317dbd" }} />
                  </IconButton>
                  <div className="c-mobilelogo">
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </div>
                  <div className="c-navlistmobile">
                    {/* <Link className="theicon">
                      <div className="theicon-link">
                        <img src={love} />
                      </div>
                      <span className="badge">2</span>
                    </Link> */}
                    <Link className="theicon" to="/shoppingcart">
                      <div className="theicon-link">
                        <img src={cart1} />
                      </div>

                      {cart != undefined ? (
                        <span className="badge_">{cart ? cart.length : null}</span>
                      ) : (
                        <></>
                      )}

                    </Link>
                  </div>
                  <Drawer
                    anchor="left"
                    open={state.left}
                    onOpen={toggleDrawer("left", true)}
                    onClose={toggleDrawer("left", false)}
                  >
                    {sideDrawerList("left")}
                    <List component="nav">
                      {category != null && category.map((item) => (
                        <>
                          <ListItem
                            // onClick={() => handleClickNested(item.category_id)}
                            onClick={() => history.push("../category/1")}
                          >
                            <ListItemText primary={item.name} />
                            {item.category_id == openList ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </ListItem>
                          <Collapse
                            in={item.category_id == openList}
                            timeout="auto"
                            unmountOnExit
                          >
                            {item.hasOwnProperty('sub_category') && item.sub_category.map((sub) => (
                              <List component="div" disablePadding>
                                <Link
                                  style={{
                                    color: "#000",
                                    textDecoration: "none",
                                  }}
                                  to={`../category/${sub.category_id}`}
                                >
                                  <ListItem button className={classes.nested}>
                                    <ListItemText primary={sub.name} />
                                  </ListItem>
                                </Link>
                              </List>
                            ))}
                          </Collapse>
                        </>
                      ))}
                      <ListItem>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/services"
                        >
                          <ListItemText primary="See All" />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          style={{ textDecoration: "none", color: "#000" }}
                          to="/services"
                        >
                          <ListItemText primary="Repair & Servicing" />
                        </Link>
                      </ListItem>
                    </List>
                  </Drawer>
                </>
              ) : (
                <>
                  <div className="c-navbar">
                    <div className="c-upper">
                      <div className="leftside">
                        <div className="c-logo">
                          <Link to="/">
                            <img src={logo} />
                          </Link>
                        </div>
                        {/* <div style={{ marginRight: "1rem" }}>
                          <TextField
                            className="form-control"
                            placeholder="Search keyword"
                          ></TextField>
                        </div>
                        <div style={{ marginRight: "1rem" }}>
                          <TextField
                            className="form-control"
                            placeholder="All Categories"
                          ></TextField>
                        </div>
                        <div>
                          <Link className="btnSearch" to="/">
                            <SearchIcon style={{ color: "#fff" }} />
                          </Link>
                        </div> */}
                      </div>
                      <div className="c-navlist">
                        {/* <Link className="theicon">
                          <div className="theicon-link">
                            <img src={love} />
                          </div>
                          <span className="badge">2</span>
                        </Link> */}
                        <Link className="theicon" to="/shoppingcart">
                          <div className="theicon-link">
                            <img src={cart1} />
                          </div>
                          {cart != undefined ? (
                            <span className="badge_">{cart ? cart.length : null}</span>
                          ) : (
                            <></>
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className="c-lower">
                      <div className="c-navlist">
                        {category != null && category.map((item, index) => (
                          <>
                            <div
                              key={index}
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              // onClick={handleMenu}
                              color="inherit"
                              className="nav-link"
                              style={{ cursor: 'pointer' }}
                              // onClick={(e) => handleClick(item.category_id, e)}

                              onClick={() => history.push("../category/1")}
                            >
                              {item.name}
                              <ExpandMoreIcon className="theiconLeft" />
                            </div>

                            <Menu
                              id="menu-appbar"
                              elevation={0}
                              getContentAnchorEl={null}
                              anchorEl={anchorEl && anchorEl[item.category_id]}
                              open={
                                anchorEl && Boolean(anchorEl[item.category_id])
                              }
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              keepMounted
                              onClose={() => setAnchorEl(null)}
                            >
                              {item.hasOwnProperty('sub_category') && item.sub_category.map((sub) => (
                                <MenuItem style={{}}>
                                  <Link
                                    className="thelisting"
                                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '390px' }}
                                    to={`../category/${sub.category_id}`}
                                  >
                                    {sub.name}
                                  </Link>
                                </MenuItem>
                              ))}
                            </Menu>
                          </>
                        ))}

                        <div
                          // key={index}
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleClickAll}
                          color="inherit"
                          className="nav-link"
                          style={{ cursor: 'pointer' }}
                        // onClick={(e) => handleClickAll(e)}
                        >
                          See All
                          <ExpandMoreIcon className="theiconLeft" />
                        </div>
                        <Menu
                          id="menu-appbar"
                          elevation={0}
                          getContentAnchorEl={null}
                          anchorEl={anchorElAll}
                          open={openAll}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          keepMounted
                          onClose={handleCloseAll}
                        >
                          {allcategory != null && allcategory.map((res) =>
                            <Link
                              className="thelisting"
                              style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '390px' }}
                            // to={`../category/${sub.category_id}`}
                            >
                              {res.name}
                            </Link>
                          )}
                          {/* {item.hasOwnProperty('sub_category') && item.sub_category.map((sub) => (
                            <MenuItem style={{}}>
                              <Link
                                className="thelisting"
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '390px' }}
                                to={`../category/${sub.category_id}`}
                              >
                                {sub.name}
                              </Link>
                            </MenuItem>
                          ))} */}
                        </Menu>
                        <Link className="nav-link_" to="/services">Repair & Servicing</Link>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Toolbar>
          </AppBar>
        </Container>
      </div>
    </>
  );
}

export default withRouter(Header);
