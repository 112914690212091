import React from "react";
import {
	Container,
	Checkbox,
	Grid,
	Select,
	FormControl,
	MenuItem,
	Modal,
	TextField,
} from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import {
	FormInputSelect,
} from "./styles";
import useStyles, { PhoneInput } from "./styles";
import { Add, Close, Edit, Remove } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import {
	get_price_type,
	get_installment_period,
	calculate_checkout,
	purchase,
	get_product_detail,

} from "../../api/API";

import useCart from "../../hooks/useCart";

import Header from "../../components/Header/Header";
import "./Addon.css"


export default function AddOn(props) {
	const classes = useStyles();
	let { AddOn } = useLocation();
	let param = useParams();
	const mainProduct = props.location.state.product
	console.log('mainProduct', mainProduct, 'props', props)

	const [token, setToken] = React.useState("")

	React.useEffect(() => {
		window.scrollTo(0, 0);
		var token = window.localStorage.getItem("token");
		if (token == "" || token == undefined || token == 'undefined') {
			setToken(token)
		}
	}, [AddOn]);

	const [isLoading, setIsLoading] = React.useState(false);

	const [count, setCount] = React.useState(1);


	const {
		getCart,
		addProduct,
	} = useCart();

	const [cart, setCart] = React.useState(getCart);

	const [cartCount, setCartCount] = React.useState(0);

	const cartItem = window.localStorage.getItem("cart");

	const countCart = JSON.parse(cartItem);

	React.useEffect(() => {
		setCart(getCart());
	}, [cartCount, getCart]);

	const [userData, setUserData] = React.useState({
		first_name: "",
		last_name: "",
		email: "",
		contact: "",
		address_1: "",
		phone: "",
		token: token
		// postcode: "",
		// city: "",
		// state: ""
	})

	const handleChange = (event) => {
		setUserData({
			...userData,
			[event.target.name]: event.target.value,
		});
	};



	const [productPriceType, setProductPriceType] = React.useState([])

	const [selectedPriceTypeID, setselectedPriceTypeID] = React.useState()

	const [selectedPriceType, setSelectedPriceType] = React.useState("");

	const [productInstallment, setProductInstallment] = React.useState([])

	const [selectedPriceInstallment, setselectedPriceInstallment] = React.useState('')


	const getPriceType = async () => {

		var resPriceType = await get_price_type({});

		if (resPriceType.status) {
			setProductPriceType(resPriceType.data)
			setselectedPriceTypeID(resPriceType.data[0].price_type_id)
			setSelectedPriceType(resPriceType.data[0].price_type)

			// setLoading(false);
		}

		var resInstallment = await get_installment_period({});
		if (resInstallment.status) {
			setProductInstallment(resInstallment.data)
			setselectedPriceInstallment(resInstallment.data[0].installment_period_id)
		}
	}

	const handleCheck = (check) => {
		setselectedPriceTypeID(check);
	};
	const [options, setOptions] = React.useState();

	const getProduct = async () => {
		var resOptions = await get_product_detail({
			product_id: props.location.state.product_id
		});
		if (resOptions.status) {
			var tempData = resOptions.data[0].add_on
			var tempOptions = []
			for (var i = 0; i < tempData.length; i++) {
				tempOptions.push({
					...tempData[i],
					checked: false,
					quantity: 1,
				});
			}
			setOptions(tempOptions)
			// setLoading(false);
		}
	}

	const getTotal = () => {

		var temp_data = [];
		var total_selected_price = 0;
		var total_bundle_price = 0;
		if (options != undefined) {

			for (var i = 0; i < options.length; i++) {

				if (options[i].checked == true) {
					total_selected_price += (options[i].total_price * options[i].quantity);
				}
			}
			if (selectedPriceTypeID == 1) {
				total_bundle_price = ((mainProduct.cash_rate * count) + total_selected_price)
			} else if (selectedPriceTypeID == 2 && selectedPriceInstallment == 1) {
				total_bundle_price = ((mainProduct.installment_rate * count) + total_selected_price)
			} else if (selectedPriceTypeID == 2 && selectedPriceInstallment == 2) {
				total_bundle_price = ((mainProduct.installment_rate_2 * count) + total_selected_price)
			} else {
				total_bundle_price = ((mainProduct.online_payment_rate * count) + total_selected_price)
			}
			return {
				total_bundle_price: (total_bundle_price).toFixed(2)
			};
		}
	}

	const handleSelect = (index) => {
		var temp_data = [...options]
		temp_data[index]['checked'] = !temp_data[index]['checked'];
		setOptions(temp_data);
		getTotal();
	};

	const handleChangeQty = (new_qty) => {
		if (new_qty < 1) {
			return;
		}
		setCount(new_qty);
	}


	const handleChangeQtyOption = (index, new_qty) => {
		console.log(index, new_qty)
		if (new_qty < 1) {
			return;
		}
		var temp_data = [...options]
		temp_data[index]['quantity'] = new_qty;
		setOptions(temp_data);
		getTotal()
	}

	React.useEffect(() => {
		getPriceType();
		getProduct();
	}, [])


	const handleCheckout = () => {
		var temp_options = []
		var productData = {
			product_id: props.location.state.product_id,
			variation_id: mainProduct.variation_id,
			product_variation_id: mainProduct.product_variation_id,
			product_image: mainProduct.product_image,
			variant: mainProduct.variation,
			variant_selection: mainProduct.sku,
			product: mainProduct.product,
			quantity: count,
			installment_rate: mainProduct.installment_rate,
			installment_rate_2: mainProduct.installment_rate_2,
			cash_rate: mainProduct.cash_rate,
			online_payment_rate: mainProduct.online_payment_rate,
			deliverytype: props.location.state.delivery_type,
			// installment_rate_per_month: selectedVariantSelection.installment_rate_per_month,
			// price: selectedVariantSelection.price,
		}
		console.log(options)
		options.map(data => {
			if (data.checked == true) {
				temp_options.push(data);
			} else {
				temp_options.slice(data);
			}
		});

		console.log('temp_options', temp_options)
		var data = {
			...productData,
			add_on: temp_options
		}
		console.log('data', data)
		addProduct(data);
	}


	return (
		<React.Fragment>
			<Header />
			<Container>
				<Section>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={5}>
							<Card>
								{/* <Paragraph
									size="500"
									bold="600"
									color="#1e1e1e"
									margin="0 0 1.8rem 0"
								>
									Shipping Address
								</Paragraph> */}


								<>
									<div className="theSubTitle">
										<Paragraph
											size="500"
											bold="600"
											color="#1e1e1e"
											margin="0 0 1.4rem 0"
										>
											Payment Method
										</Paragraph>
									</div>

									<Grid container spacing={2}>
										<>
											{productPriceType.map(item => (
												<Grid
													item
													xs={12}
													sm={12}
													md={12}
													onClick={() => handleCheck(item.price_type_id)}
													className={
														selectedPriceTypeID == item.price_type_id
															? classes.shipping_selection_active
															: classes.shipping_selection
													}
												>

													{item.price_type}
													{/* <Paragraph style={{ fontSize: '1rem', display: 'block' }} margin="0 0 0 1rem" bold="600"> RM 100.00</Paragraph> */}
												</Grid>
											))}
										</>



									</Grid>
									<br />
									{selectedPriceTypeID == 2 ? (
										<>
											<Paragraph
												size="500"
												bold="600"
												color="#1e1e1e"
												margin="0 0 1.4rem 0"
											>
												Payment Method
											</Paragraph>

											<Grid container spacing={3}>
												<>
													{productInstallment.map(item => (
														<Grid
															item
															xs={6}

															onClick={() => setselectedPriceInstallment(item.installment_period_id)}
															className={
																selectedPriceInstallment == item.installment_period_id
																	? classes.shipping_selection_active
																	: classes.shipping_selection
															}
														>
															{item.period} months
														</Grid>
													))}
												</>
											</Grid>
										</>

									) : null}



									<div className={classes.theDivider}></div>
									<br />
									{/* <div className={classes.theFlexCalculate}>
										<Paragraph size="500" color="#1e1e1e">
											SubTotal
										</Paragraph>
										<Paragraph size="500" color="#1e1e1e" bold="500">
											RM{" "}
											{
												!isNaN(
													parseFloat(
														totalAmount
													).toFixed(2)
												) ?
													parseFloat(
														totalAmount
													).toFixed(2) : 0.00

											}
										</Paragraph>
									</div> */}
									<div className={classes.theFlexCalculate}>
										<Paragraph size="500" bold="600" color="#1e1e1e">
											Total Price
										</Paragraph>
										{getTotal() != undefined &&
											<>

												{selectedPriceTypeID == 1 ?
													<Paragraph size="500" bold="600" color="#7f222c">
														RM {getTotal().total_bundle_price}
													</Paragraph>
													:
													<></>
												}
												{selectedPriceTypeID == 2 ?
													<>
														{selectedPriceInstallment == 1 ?
															<Paragraph size="500" bold="600" color="#7f222c">
																RM {getTotal().total_bundle_price} / 12 months
															</Paragraph>
															:
															<Paragraph size="500" bold="600" color="#7f222c">
																RM {getTotal().total_bundle_price} / 24 months
															</Paragraph>
														}
													</>
													:
													<></>
												}
												{selectedPriceTypeID == 3 ?
													<Paragraph size="500" bold="600" color="#7f222c">
														RM {getTotal().total_bundle_price}
													</Paragraph>
													:
													<></>
												}
											</>
										}
									</div>
									{!isLoading ? (
										<>
											<div className={classes.theAddOn}>
												<Button
													label="Checkout"
													bold="600"
													bg="#869623"
													color="#fff"
													onClick={() => handleCheckout()}
												/>
											</div>
										</>
									) : (
										<div style={{
											textAlign: "center"
										}}>
											<CircularProgress style={{

												textAlign: "center"
											}}></CircularProgress>
										</div>
									)}
									{/* <Grid container spacing={2}>
										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="Postcode"
												name="postcode"
												label="Postcode"
												color="#1e1e1e"
												bold="600"
												type="number"
												onChange={handleChange}
											/>
										</Grid>

										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="City"
												name="city"
												label="City"
												color="#1e1e1e"
												bold="600"
												onChange={handleChange}
											/>
										</Grid>
									</Grid> */}
								</>

							</Card>
						</Grid>

						<Grid item xs={12} sm={12} md={7}>
							<div className={classes.theTable}>
								<Paragraph
									size="500"
									color="#1e1e1e"
									bold="500"
									margin="1rem 0"
								>
									Buy Together Save More
								</Paragraph>
								<div className="cart_product_list">
									{mainProduct != undefined ?
										<div
											className={classes.theTableList}
										>
											<div className={classes.theFlex}>
												<div className={classes.theProdIMG}>
													<img className={classes.img} src={mainProduct.product_image} />
												</div>
												<div className={classes.theDetail}>
													<Paragraph
														size="300"
														color="#1e1e1e"
														bold="600"
														margin="0 0 0.4rem 0"
														className={classes.prod}
													>
														{mainProduct.product}
													</Paragraph>

													<div className={classes.theQuantity}>
														<Paragraph
															size="200"
															color="#1e1e1e"
															bold="500"
															margin="0rem 1rem 0 0"
														>
															Variant :
														</Paragraph>

														<Paragraph
															size="300"
															color="#878787"
															bold="600"
														>
															{mainProduct.sku}
														</Paragraph>
													</div>
													{selectedPriceTypeID == 1 ?
														<div className={classes.theQuantity}>
															<Paragraph
																size="200"
																color="#1e1e1e"
																bold="500"
																margin="0rem 1rem 0 0"
															>
																Price :
															</Paragraph>

															<Paragraph
																size="300"
																color="#878787"
																bold="600"
															>
																RM {(mainProduct.cash_rate * count).toFixed(2)}
															</Paragraph>
														</div>
														:
														null
													}
													{selectedPriceTypeID == 2 ?
														<div className={classes.theQuantity}>
															<Paragraph
																size="200"
																color="#1e1e1e"
																bold="500"
																margin="0rem 1rem 0 0"
															>
																Price :
															</Paragraph>

															{selectedPriceInstallment == 1 ?
																<Paragraph
																	size="300"
																	color="#878787"
																	bold="600"
																>
																	{(mainProduct.installment_rate * count).toFixed(2)} / 12 month
																</Paragraph>
																:
																<Paragraph
																	size="300"
																	color="#878787"
																	bold="600"
																>
																	{(mainProduct.installment_rate_2 * count).toFixed(2)} / 24 month
																</Paragraph>
															}
														</div>
														:
														null
													}
													{selectedPriceTypeID == 3 ?
														<div className={classes.theQuantity}>
															<Paragraph
																size="200"
																color="#1e1e1e"
																bold="500"
																margin="0rem 1rem 0 0"
															>
																Price :
															</Paragraph>

															<Paragraph
																size="300"
																color="#878787"
																bold="600"
															>
																RM {(mainProduct.online_payment_rate * count).toFixed(2)}
															</Paragraph>
														</div>
														:
														null
													}

													<div className="theFlex_" style={{ alignItems: "center" }}>
														{/* <p className="label">Quantity</p> */}
														<div className="theQuantityContainer">
															<div
																className="theIncrement"
																onClick={() => handleChangeQty(count - 1)}
															>
																<Remove />
															</div>
															<p className="theCount">{count}</p>
															<div
																className="theIncrement"
																onClick={() => handleChangeQty(count + 1)}
															>
																<Add />
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
										: null}
								</div>
								<div style={{
									backgroundColor: '#d5f6fe',
									padding: 14,
								}}>

									<Paragraph
										size="300"
										color="#878787"
										bold="600"
									>
										Buy one then get items below with favourable price
									</Paragraph>
								</div>
								<div className="cart_product_list">
									{options != undefined && options.length > 0
										? options.map((item, index) => (
											<div
												className={classes.theTableList}
											>
												<div className={classes.theFlex}>

													<div>
														<Checkbox
															onChange={() => handleSelect(index)}
															// onChange={handleChange}
															color="primary"
															inputProps={{
																"aria-label": "secondary checkbox",
															}}
															name="is_active"
															checked={item.checked}
														// value={changeText}
														/>
													</div>
													<div className={classes.theProdIMG}>
														<img className={classes.img} src={item.image} />
													</div>
													<div className={classes.theDetail}>
														<Paragraph
															size="300"
															color="#1e1e1e"
															bold="600"
															margin="0 0 0.4rem 0"
															className={classes.prod}
														>
															{item.name}
														</Paragraph>

														<div className={classes.theQuantity}>
															<Paragraph
																size="200"
																color="#1e1e1e"
																bold="500"
																margin="0rem 1rem 0 0"
															>
																Price :
															</Paragraph>

															<Paragraph
																size="300"
																color="#878787"
																bold="600"
															>
																RM {(item.total_price * item.quantity).toFixed(2)}
															</Paragraph>
														</div>


													</div>
													<div className="theFlex_" style={{ alignItems: "center" }}>
														{/* <p className="label">Quantity</p> */}
														<div className="theQuantityContainer">
															<div
																className="theIncrement"
																onClick={() => handleChangeQtyOption(index, item.quantity - 1)}
															>
																<Remove />
															</div>
															<p className="theCount">{item.quantity}</p>
															<div
																className="theIncrement"
																onClick={() => handleChangeQtyOption(index, item.quantity + 1)}
															>
																<Add />
															</div>
														</div>
													</div>
												</div>
											</div>
										))
										: null}
								</div>

							</div>
						</Grid>
					</Grid>
				</Section>
			</Container >

		</React.Fragment >
	);
}
