import React, {
  useState,
  useEffect
} from "react";
import { Container, Grid } from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import {
  Link,
  useLocation,
  useParams,
  useHistory
} from "react-router-dom";

import {
  Add,
  ArrowBack,
  ChevronRight,
  Close,
  Edit,
  Remove,
} from "@material-ui/icons";
import service001 from "../../assets/images/service001.png";
import "./ServiceDetail.css";
import {
  get_service_detail,
  get_profile,
} from '../../api/API'

export default function ServiceDetail() {

  let { service_list_id } = useParams()

  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [profileData, setProfileData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    address_1: '',
    problem: '',
    postcode: '',
    city: null,
    state: null,
    city_label: '',
    state_label: '',
  });

  const getProfile = async () => {
    // setLoading(true);
    var resProfile = await get_profile({});
    if (resProfile.status) {
      setProfileData({
        ...profileData,
        phone: resProfile.data[0].contact,
        first_name: resProfile.data[0].first_name,
        last_name: resProfile.data[0].last_name,
        email: resProfile.data[0].email,
        // last_name: '',
        // address_1: '',
        // postcode: '',
        city: null,
        state: null,
        city_label: '',
        state_label: '',
      })
      // setLoading(false);
    }
  }

  const getData = async () => {
    setLoading(true);
    var resJob = await get_service_detail({ product_id: service_list_id });
    if (resJob.status) {
      setJobData(resJob.data[0])
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
    getProfile();
  }, [])

  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theJobDetailSection">
          <div className="theClientBox">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <div className="theCardBox">
                  <div className="theCardConHeader">
                    <p>Client Information</p>
                  </div>
                  <div className="theCardConBody">
                    <div className="theFormGroup">
                      <p className="label">CLIENT NAME</p>
                      <p className="bold">{profileData.first_name} {profileData.last_name}</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">PHONE NUMBER</p>
                      <p className="bold">0123456789</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">BUILDING TYPE</p>
                      <p className="bold">xxx</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">ADDRESS</p>
                      <p className="bold">xxx,xxx</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">SERVICE DATE</p>
                      <p className="bold">2021-06-03</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <div className="theCardBox">
                  <div className="theCardConHeader">
                    <p>Extra Information</p>
                  </div>
                  <div className="theCardConBody">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} md={4}>
                        <div className="theServiceIMG">
                          <img src={jobData != null && jobData.product_image[0].product_image} />
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} sm={4} md={4}>
                        <div className="theServiceIMG">
                          <img src={service001} />
                        </div>
                      </Grid> */}
                    </Grid>
                    <div className="theFormGroup">
                      <p className="label">DESCRIPTION</p>
                      <p className="bold">
                        {jobData != null && jobData.description}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="theJobServiceList">
            <div className="theCardCon">
              <div className="theCardConHeader">
                <p>Job Listing</p>
              </div>
              <div className="theCardConBody">
                <div className="theTableList">
                  <div className="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Job Detail</th>
                          <th width="10%" style={{ textAlign: "center" }}>
                            Quantity
                          </th>
                          <th width="20%" style={{ textAlign: "center" }}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="theFlex">
                              <div className="theDetail">
                                <p>{jobData != null && jobData.name}</p>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="theQuantity">
                              <div className="theIncrement">
                                <div className="theBox">
                                  <p>0</p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p style={{ margin: "0", color: "#00bfec", fontWeight: "700" }}>Normal Price: RM {jobData != null && jobData.cash_rate}</p>
                            <p style={{ margin: "0", color: "#E6B10E", fontWeight: "700" }}>Instant Transfer: RM {jobData != null && jobData.online_payment_rate}</p>
                            <p style={{ margin: "0", color: "#e50b2c", fontWeight: "700" }}>Installment: RM {jobData != null && jobData.installment_rate} / 12 months</p>
                            <p style={{ margin: "0", color: "#e50b2c", fontWeight: "700" }}>Installment: RM {jobData != null && jobData.installment_rate_2} / 24 months </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
