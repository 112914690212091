import React from "react";
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import avatar from "../../assets/images/avantar.png";
import coin from "../../assets/images/coin.svg";
import point from "../../assets/images/point.svg";
import "./Order.css";

import { get_profile, get_orderhistory } from "../../api/API";

export default function Order() {
  const [day, setDay] = React.useState("");
  const handleChangeDay = (event) => {
    setDay(event.target.value);
  };
  const [tab, setTab] = React.useState("Latest");
  const handleChangeTab = (text) => {
    setTab(text);
  };

  //
  const [token, setToken] = React.useState("");
  const [user, setUser] = React.useState({});
  const [order, setOrder] = React.useState([]);

  React.useEffect(() => {
    let postparam = {
      token: token,
    };
    get_profile(postparam).then((json) => {
      setUser(json.data[0]);
    });
    get_orderhistory(postparam).then((json) => {
      setOrder(json.data);
    })
  }, []);




  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theOrderSection">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={3}>
              <div className="theUserCard">
                <div className="theCardHeader">
                  <div className="theAvatar">
                    {/* <img src={avatar} /> */}
                    <p>{user.name}</p>
                  </div>
                </div>
                <div className="theCardBody">
                  <Link className="theCoin" to="/coinreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={coin} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Coins</p>
                        <p className="coin">9,000 Coins</p>
                      </div>
                    </div>
                  </Link>
                  {/* <Link className="theCoin" to="/pointreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={point} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Points</p>
                        <p className="point">9,000 Points</p>
                      </div>
                    </div>
                  </Link> */}
                  <div class="theLink">
                    <Link className="theNavLink" to="/profile">
                      My Profile
                    </Link>
                    <Link className="theNavLink" to="/address">
                      Address
                    </Link>
                    <Link className="theNavLinkActive" to="/order">
                      Order History
                    </Link>
                    <Link className="theNavLink" to="/referfriend">
                      Refer a Friend
                    </Link>
                    <Link className="theNavLink" to="/becomeconstructor">
                      Become Constructor
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={9}>
              <div className="theCardCon">
                <div className="theCardConHeader">
                  <p>My Order</p>
                </div>
                <div className="theCardConBody">
                  <Grid container spacing={3}>
                    {order.map((item) => (
                      <Grid item xs={12} sm={6} md={6}>
                        <Link className="theCardOrder" to={`../orderdetail/${item.orders_id}`}>
                          <h3>Order Number: {item.orders_id}</h3>
                          <p>
                            Payment On: <span>{item.created_date}</span>
                          </p>
                          <p>
                            Order Total: <span className="price">RM {item.amount}</span>
                          </p>
                          <p>
                            Payment Method: <span>{item.payment_method}</span>
                          </p>
                          {/* <p>
                            Status: <span>Completed</span>
                          </p> */}
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
