import React, { useState } from "react";
import { Link } from "react-router-dom";

//material-ui
import {
  Modal,
  Card,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";

import Previews from "./Previews"

import { get_product, get_category, register_warranty, upload_proof_image } from "../../api/API";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

export default function WarrantyRegisterModal(props) {
  const classes = useStyles();
  const [form, setForm] = useState({
    category_id: "",
    product_id: "",
    serial_no: "",
    purchase_date: "",
    address1: "",
    address2: "",
    postcode: "",
    state: "",
    receipt:""
  });
  const [category, setCategory] = React.useState([]);
  const [product, setProduct] = React.useState([]);

  React.useEffect(() => {
    get_product().then((json) => {
      setProduct(json.data);
    });
    get_category().then((json) => {
      setCategory(json.data);
    });
  }, []);

  // const handleChangeProductCategory = (event) => {
  //     setProductCategory(event.target.value);
  // };

  // const handleChangeProduct = (event) => {
  //     setProduct(event.target.value);
  // };

  const handleChange = (event) => {
    event.persist();
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };


  const handleNext = (event) => {
    if (
      form.product_id.length == 0 ||
      form.category_id.length == 0 ||
      form.serial_no.length == 0 ||
      form.purchase_date.length == 0 ||
      form.address1.length == 0 ||
      form.address2.length == 0 ||
      form.postcode.length == 0 ||
      form.state.length == 0
    ) {
      alert("All Field Required");
    } else {
      props.setNext(true);
      setForm({
        ...form,
      });
    }
  };

  const handleRegisterWarranty = () => {
    register_warranty(form).then((json) => {
      if (form.receipt.length == 0) {
        alert("Please upload a receipt");
      } else {
        if (json.status) {
          console.log(JSON.stringify(json));
        } else {
          // console.log(json)
        }
      }
    });
  };

  const onUploaded = (serverFile) => {
      console.log(serverFile)
    setForm({
        ...form,
        receipt: serverFile.data.receipt
    });
};

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="c-register"
    >
      <Card className="b-register">
        <div className="card-overflow">
          <Grid container spacing={4}>
            <Grid item xs={12} md={9}>
              <div className="c-title">
                <h2>Register Your Warranty</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incid idunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exerci tation
                  ullamco laboris nisi ut aliquip
                </p>
              </div>
            </Grid>
            <div className="close-button">
              <IconButton onClick={props.handleClose}>
                <Clear />
              </IconButton>
            </div>
            {!props.next ? (
              <React.Fragment>
                <Grid item container xs={12} md={6} alignContent="flex-start">
                  <FormControl className={classes.formControl}>
                    <Select
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="form-control"
                      name="category_id"
                      value={form.category}
                    >
                      <MenuItem disabled>
                        -- Select Product Category --
                      </MenuItem>
                      {category.map((item) => (
                        <MenuItem
                          key={item.category_id}
                          value={item.category_id}
                        >
                          {item.category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <Select
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="form-control"
                      value={form.name}
                      name="product_id"
                    >
                      <MenuItem disabled> -- Select Product -- </MenuItem>
                      {product.map((item) => (
                        <MenuItem key={item.product_id} value={item.product_id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container xs={12} md={6} className="c-textfield">
                  <Grid item xs={12}>
                    <TextField
                      className="textfield"
                      label="Serial No"
                      size="small"
                      fullWidth
                      name="serial_no"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="textfield"
                      label="Date of Purchase"
                      size="small"
                      fullWidth
                      name="purchase_date"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="textfield"
                      label="Address Line 1"
                      size="small"
                      fullWidth
                      name="address1"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="textfield"
                      label="Address Line 2"
                      size="small"
                      fullWidth
                      name="address2"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="textfield"
                      label="Post Code"
                      size="small"
                      fullWidth
                      name="postcode"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="textfield"
                      label="State"
                      size="small"
                      fullWidth
                      name="state"
                      onChange={handleChange}
                    />
                  </Grid>
                  <div
                    className="c-btncheckout"
                    style={{ marginTop: "20px", cursor: "pointer" }}
                  >
                    <div
                      className="theCheckoutBtn btn-primary"
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      Next
                    </div>
                  </div>
                </Grid>
              </React.Fragment>
            ) : (
              <Grid container item xs={12} justify="center">
                <Grid item xs={10} lg={6}>
                  <h3>Proof of purchase</h3>
                  <Previews onUploaded={onUploaded} />
                  <div className="c-btncheckout" style={{ marginTop: "20px" }}>
                    <Link
                      className="proof-next theCheckoutBtn btn-primary"
                      onClick={() => {
                        handleRegisterWarranty();
                      }}
                    >
                      Submit
                    </Link>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Card>
    </Modal>
  );
}
