import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FormControl, TextField } from "@material-ui/core";
import { md } from "../../styles/responsive";
// import { ComboboxInput } from "@reach/combobox";

export const config = {
  root: {},
  theHeaderProfile: {
    position: "relative",
  },
  theTable: {
    position: "relative",
    whiteSpace: "nowrap",
    // overflow: "scroll",
    padding: " 1rem",
    boxShadow: "0px 0px 4px 1px rgb(0 0 0 / 8%)",
    width: '100%',

    "& table": {
      position: "relative",
      width: "100%",
    },
  },
  theBorderHeader: {
    position: "relative",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "scroll",

    "& th": {
      borderBottom: "1px solid #eaeaea",
    },
  },

  theTableList: {
    "& td": {
      padding: "1rem 0",
      borderBottom: "1px solid #eaeaea",
    },
  },

  theProduct: {
    alignItems: "center",
    display: "flex",
  },

  theFlex: {
    borderBottom: "1px solid #eaeaea",
  },

  theFlexInner: {
    alignItems: "center",
    display: "flex",
    padding: "1rem 0",
    margin: '1rem',
  },
  theFlexAddon: {
    borderTop: "1px solid #eaeaea",
  },

  theFlexAddonInner: {
    alignItems: "center",
    display: "flex",
    padding: "1rem 0",
    margin: '1rem',
  },

  theProdIMG: {
    position: "relative",
    width: "80px",
    boxShadow: " 0px 0px 4px 1px rgb(0 0 0 / 8%)",
  },

  img: {
    width: "100%",
    height: "60px",
    objectFit: "contain",
  },

  theQuantity: {
    position: "relative",
    alignItems: "center",
    // justifyContent: "center",
    display: "flex",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: "0.2rem 0",
  },

  theDetail: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginLeft: "1.4rem",
    whiteSpace: "break-spaces"

    // display: "flex",
  },

  theIncrement: {
    border: "1px solid #d6d6d6",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },

  theIncrementBtn: {
    padding: "0 .8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  theBtnStyle: {
    color: "#1e1e1e",
    fontSize: "1rem",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theAddressList: {
    position: "relative",
    marginBottom: "1rem",
  },

  theAddressFlex: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },

  theRow: {
    display: "flex",
    alignItems: "center",
  },

  theIconIMG: {
    position: "relative",
    width: "24px",
    marginRight: "1rem",
  },

  theAddressBox: {
    position: "relative",
    with: "80%",
  },

  theEdit: {
    width: "20%",
    color: "#7f222c",
    display: "block",
    textAlign: "right",
    fontWeight: "600",
  },

  theFlexCalculate: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    margin: "0 0 1rem 0",
  },

  theCheckout: {
    position: "relative",
    margin: "1rem 0",
  },

  thePadding: {
    padding: "0 1.4rem",
  },

  theScrollNoHeight: {
    position: "relative",
    overflow: "scroll",
  },

  theScroll: {
    position: "relative",
    height: "500px",
    overflow: "scroll",
  },

  theCardAddressActive: {
    position: "relative",
    border: "2px solid #7f222c",
    padding: "1rem",
    borderRadius: "4px",
    marginBottom: "1rem",
    cursor: "pointer",
  },

  theCardAddress: {
    position: "relative",
    border: "2px solid #d7d7d7",
    padding: "1rem",
    borderRadius: "4px",
    marginBottom: "1rem",
    cursor: "pointer",
  },

  theHiddenRadioButton: {
    display: "none",
  },

  theSubmit: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    marginTop: "2rem",
  },

  prod: {
    // flexWrap: "wrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // width: "180px",
    // whiteSpace: "nowrap",
  },
  theCloseIcon: {
    color: "#ec0f25",
  },

  theTotalPrice: {
    // margin: "0 3rem",
    ...md("md", {
      margin: "0",
    }),
  },

  ul: {
    margin: "0",
    cursor: "pointer",
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  li: {
    cursor: "pointer",
    margin: 0,
    padding: ".25rem .5rem",
  },
  listing: {
    borderBottom: "1px solid #eaeaea",
    borderRight: "1px solid #eaeaea",
    borderLeft: "1px solid #eaeaea",
    borderTop: "1px solid transparent",
    // background: "rgba(255,255,255,.99)",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    fontSize: "85%",
    marginTop: "-2px",
  },

  shipping_selection: {
    border: "1px solid #eaeaea",
    padding: "14px !important",
    borderRadius: "6px",
    cursor: "pointer",
    // marginRight: 10,
    display: "flex",
    marginBottom: 12
  },

  shipping_selection_active: {
    border: "1px solid #ffae33",
    padding: "14px !important",
    borderRadius: "6px",
    cursor: "pointer",
    // marginRight: 10,
    display: "flex",
    marginBottom: 12
  },

  theDivider: {
    border: "1px solid #eaeaea",
    margin: "1rem 0",
  },

  theFlexRow: {
    justifyContent: "space-between",
    alignItems: "center",
    ...md("sm", {
      display: "flex",
    }),
  },

  paddBtn: {
    marginTop: "1rem",
    display: "block",
    ...md("sm", {
      marginTop: "0",
    }),
  },

  theGiftCard: {
    // margin:" 1rem 0"
  },

  theFlexGiftCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    padding: " 1rem 0"
  },

  theCalculate: {
    borderTop: "1px solid #eaeaea",
  }
};

export const ModalAddress = styled.div`
  position: absolute;
  background-color: #fff;
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ccc;
  padding: 1rem;
  @media screen and (min-width: 768px) {
    width: 70%;
  }
`;

export const ModalTime = styled.div`
  position: absolute;
  background-color: #fff;
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ccc;
  padding: 1rem;
  @media screen and (min-width: 768px) {
    width: 60%;
  }
  @media screen and (min-width: 1600px) {
    width: 20%;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 1rem 0 0;
`;

export const FlexIcon = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 2rem;
`;

export const ModalBodyTime = styled.div`
  position: relative;
  padding: 0 1rem 1em 1rem;
`;

export const FormBox = styled.div`
  position: relative;
  /* width: 90%; */
  margin: 0 auto;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const PhoneInput = styled(TextField)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  /* border-radius: 6px; */
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-indent: 14px; */
  width: 100%;
  
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 90%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

// export const DropdownList = styled(ComboboxInput)`
//   display: block;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   color: #495057;
//   background-color: #fff;
//   background-clip: padding-box;
//   border-top: 1px solid #eaeaea;
//   border-left: 1px solid #eaeaea;
//   border-right: 1px solid #eaeaea;
//   border-bottom: 1px solid #eaeaea;
//   border-radius: 6px;
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 0;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   margin-top: 1rem;
//   width: calc(100% + -24px);
//   /* .MuiInput-underline:before {
//     border-bottom: none !important;
//   }
//   .MuiInput-underline:after {
//     border-bottom: none !important;
//   }
//   .MuiInputBase-root {
//     width: 100%;
//   }
//   .MuiInputBase-root {
//     font-weight: 500 !important;
//   }
//   .MuiInput-underline {
//     &:before {
//       position: relative !important;
//     }
//   } */
// `;

export default makeStyles(config);
