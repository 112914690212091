import React from "react";
import { Container, Grid } from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  Add,
  ArrowBack,
  ChevronRight,
  Close,
  Edit,
  Remove,
} from "@material-ui/icons";
import service001 from "../../assets/images/service001.png";
import "./JobDetail.css";

export default function JobDetail() {
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theJobDetailSection">
          <div className="theClientBox">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <div className="theCardBox">
                  <div className="theCardConHeader">
                    <p>Client Information</p>
                  </div>
                  <div className="theCardConBody">
                    <div className="theFormGroup">
                      <p className="label">CLIENT NAME</p>
                      <p className="bold">John Doe</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">PHONE NUMBER</p>
                      <p className="bold">0123456789</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">BUILDING TYPE</p>
                      <p className="bold">xxx</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">ADDRESS</p>
                      <p className="bold">xxx,xxx</p>
                    </div>
                    <div className="theFormGroup">
                      <p className="label">SERVICE DATE</p>
                      <p className="bold">2021-06-03</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <div className="theCardBox">
                  <div className="theCardConHeader">
                    <p>Extra Information</p>
                  </div>
                  <div className="theCardConBody">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} md={4}>
                        <div className="theServiceIMG">
                          <img src={service001} />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <div className="theServiceIMG">
                          <img src={service001} />
                        </div>
                      </Grid>
                    </Grid>
                    <div className="theFormGroup">
                      <p className="label">REMARKS</p>
                      <p className="bold">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Mauris viverra, elit a semper imperdiet, massa ex porta
                        nisi, dignissim auctor nisi tortor et nunc. Duis eu
                        felis ut nibh suscipit ultrices.
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="theJobServiceList">
            <div className="theCardCon">
              <div className="theCardConHeader">
                <p>Job Listing</p>
              </div>
              <div className="theCardConBody">
                <div className="theTableList">
                  <div className="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Job Detail</th>
                          <th width="10%" style={{ textAlign: "center" }}>
                            Quantity
                          </th>
                          <th width="20%" style={{ textAlign: "center" }}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="theFlex">
                              <div className="theDetail">
                                <p>Chemical Treatment</p>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className="theQuantity">
                              <div className="theIncrement">
                                <div className="theBox">
                                  <p>0</p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>RM 1,900.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="theApply">
            <Link className="theApplyBtn">Apply Now</Link>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
