import React from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import {
    Container,
    Grid,
    TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const TermsCondition = () => {


    let { TermsCondition } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [TermsCondition]);

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="static_pages">
                    <div className="mobile_container page_padding">
                        <h5 className="h_font_1"><i>Terms & Conditions</i></h5>

                        <h5 className="h_font_1">1. Terms and Conditions of Use Agreement</h5>
                        <p className="p_font_New2">
                            PLEASE READ THE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS SITE. We maintain this web site as a service to our customers, and by using our site you are agreeing to comply with and be bound by the following terms of use. Please review the following terms and conditions carefully, and check them periodically for changes.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Acceptance of Agreement. </span>You agree to the terms and conditions outlined in this Terms and Conditions of use Agreement ("Agreement") with respect to our site (the "Site"). This Agreement constitutes the entire and only agreement between us and you, and supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the Site, the content, products or services provided by or through the Site, and the subject matter of this Agreement. This Agreement may be amended by us at any time and from time to time without specific notice to you.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Copyright. </span>The content, organization, graphics, design, compilation, magnetic translation, digital conversion and other matters related to the Site are protected under applicable copyrights, trademarks, registered trademarks and other proprietary (including but not limited to intellectual property) rights. The copying, redistribution, use or publication by you of any such matters or any part of the Site, except as allowed by Section 4, is strictly prohibited. You do not acquire ownership rights to any content, document or other materials viewed through the Site. The posting of information or materials on the Site does not constitute a waiver of any right in such information and materials.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Images. </span>All images used in this website are solely for illustration purposes only; actual item / colour may vary.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Fraud. </span>By becoming a member, you confirm that the information provided in this form is true and that you agree to abide by the Terms and Conditions of use of this site. Please note that your membership can be cancelled without notice if it is determined that false or misleading information has been provided, the Terms and Conditions of use have been violated, or other abuses have occurred as determined by Sin Ma Air-Con & Electrical SDN. BHD. in its sole discretion. If membership has been revoked, Sin Ma Air-Con & Electrical SDN. BHD. reserves the right to refuse application or readmission to the membership program.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Limited Right to Use. </span>The viewing, printing or downloading of any content, graphic, form or document from the Site grants you only a limited, nonexclusive license for use solely by you for your own personal use and not for republication, distribution, assignment, sublicense, sale, preparation of derivative works or other use. No part of any content, form or document may be reproduced in any form or incorporated into any information retrieval system, electronic or mechanical, other than for your personal use (but not for resale or redistribution).
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Editing, Deleting and Modification. </span>We reserve the right in our sole discretion to edit or delete any documents, information or other content appearing on the Site, including this Agreement, without further notice to users of the Site.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Indemnification. </span>Your right to use the Site is not transferable. Any password or right given to you to obtain information or documents is not transferable and may only be used by you.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Nontransferable. </span>Your right to use the Site is not transferable. Any password or right given to you to obtain information or documents is not transferable and may only be used by you.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Disclaimer. </span>The information from or through the site are provided "as-is," "as available," and all warranties, express or implied, are disclaimed (including but not limited to the disclaimer of any implied warranties of merchantability and fitness for a particular purpose). The information and services may contain bugs, errors, problems or other limitations. We and our affiliated parties have no liability whatsoever for your use of any information or service. In particular, but not as a limitation, we and our affiliated parties are not liable for any indirect, special, incidental or consequential damages (including damages for loss of business, loss of profits, litigation, or the like), whether based on breach of contract, breach of warranty, tort (including negligence), product liability or otherwise, even if advised of the possibility of such damages. The negation of damages set forth above are fundamental elements of the basis of the bargain between us. This site and the information would not be provided without such limitations. No advice or information, whether oral or written, obtained by you from us through the site shall create any warranty, representation or guarantee not expressly stated in this agreement.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Limits. </span>All responsibility or liability for any damages caused by viruses contained within the electronic file containing the form or document is disclaimed. We will not be liable to you for any incidental, special or consequential damages of any kind that may result from use of or inability to use our site. Our maximum liability to you under all circumstances will be equal to the purchase price you pay for any goods, services or information.
                            Use of Information. We reserve the right, and you authorize us, to the use and assignment of all information regarding Site uses by you and all information provided by you in any manner consistent with our Privacy Policy.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Third-Party Services. </span>We allow access to or advertise third-party merchant sites ("Merchants") from which you may purchase or otherwise obtain certain goods or services. You understand that we do not operate or control the products or services offered by Merchants. Merchants are responsible for all aspects of order processing, fulfillment, billing and customer service. We are not a party to the transactions entered into between you and Merchants. You agree that use of such merchants is at your sole risk and is without warranties of any kind by us, expressed, implied or otherwise including warranties of title, fitness for purpose, merchantability or non-infringement under no circumstances are we liable for any damages arising from the transactions between you and merchants or for any information appearing on merchant sites or any other site linked to our site.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Third-Party Merchant Policies. </span>All rules, policies (including privacy policies) and operating procedures of Merchants will apply to you while on such sites. We are not responsible for information provided by you to Merchants. We and the Merchants are independent contractors and neither party has authority to make any representations or commitments on behalf of the other.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Privacy Policy. </span>Our Privacy Policy, as it may change from time to time, is a part of this Agreement.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Payments. </span>You represent and warrant that if you are purchasing something from us or from our Merchants that (i) any credit card information you supply is true, correct and complete, (i) charges incurred by you will be honored by your credit card company, and (iii) you will pay the charges incurred by you at the posted prices, including any shipping fees and applicable taxes.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Securities Laws. </span>This Site may include statements concerning our operations, prospects, strategies, financial condition, future economic performance and demand for our products or services, as well as our intentions, plans and objectives, that are forward-looking statements. These statements are based upon a number of assumptions and estimates which are subject to significant uncertainties, many of which are beyond our control. When used on our Site, words like "anticipates," "expects," "believes," "estimates," "seeks," "plans," "intends" and similar expressions are intended to identify forward-looking statements designed to fall within securities law safe harbors for forward-looking statements. The Site and the information contained herein does not constitute an offer or a solicitation of an offer for sale of any securities. None of the information contained herein is intended to be, and shall not be deemed to be, incorporated into any of our securities-related filings or documents.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Links to Other Web Sites. </span>The Site contains links to other Web sites. We are not responsible for the content, accuracy or opinions express in such Web sites, and such Web sites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked Web site on our Site does not imply approval or endorsement of the linked Web site by us. If you decide to leave our Site and access these third-party sites, you do so at your own risk.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Submissions. </span>All suggestions, ideas, notes, concepts and other information you may from time to time send to us (collectively, "Submissions") shall be deemed and shall remain our sole property and shall not be subject to any obligation of confidence on our part. Without limiting the foregoing, we shall be deemed to own all known and hereafter existing rights of every kind and nature regarding the Submissions and shall be entitled to unrestricted use of the Submissions for any purpose, without compensation to the provider of the Submissions.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Applicable Law. </span>You agree that all actions or proceedings arising directly or indirectly out of this agreement, or your use of the site or any samples or products obtained by you through such use, shall be litigated in the circuit court of Malaysia, Selangor. You are expressly submitting and consenting in advance to such jurisdiction in any action or proceeding in any of such courts, and are waiving any claim that Selangor is an inconvenient forum or an improper forum based on lack of venue. This site is created and controlled by SIN MA AIR-CON & ELECTRICAL SDN BHD., a wholly owned subsidiary of SIN MA AIR-CON & ELECTRICAL SDN BHD. in the State of Selangor, Malaysia. As such, the laws of Malaysia will govern the terms and conditions contained in this Agreement and elsewhere throughout the Site, without giving effect to any principles of conflicts of laws.
                        </p>
                        <p className="p_font_New2">
                            <span style={{ fontWeight: 'bold' }}>Verification of Members' Address. </span>SIN MA AIR-CON & ELECTRICAL SDN BHD. reserves the right to contact a Member via email to verify the accuracy of account information (including the Member's correct name and address) that is needed to provide the Member with the information he or she requested from SIN MA AIR-CON & ELECTRICAL SDN BHD.
                        </p>


                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment >
    );
};

export default TermsCondition;