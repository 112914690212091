import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Listing.css";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import shipping from "../../assets/images/shipping.png";
import setting from "../../assets/images/setting.png";
import tag from "../../assets/images/tag.png";
import prod001 from "../../assets/images/wall-mounted.png";
import brand from "../../assets/images/toshiba.png";
import Slider from "react-slick";

import {
  get_brand,
  get_category,
  get_sub_category,
  get_product,
  get_subcategory_by_category,
  get_product_by_category,
  get_recommended_product_web,
} from "../../api/API";

const useStyle = makeStyles((theme) => ({}));

export default function Listing() {
  let { Listing } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Listing]);

  //changeindex
  const [index, setIndex] = useState("1");
  const [childindex, setChildIndex] = useState("0");

  const onChangeIndex = (text) => {
    setIndex(text);
  };

  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  //BRAND & CATEGORY & SUB CATEGORY & PRODUCT
  const [brand, setBrand] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [subcategory, setSubCategory] = React.useState([]);
  const [displaysubcategory, setDisplaySubCategory] = React.useState([]);
  const [recommend, setRecommend] = React.useState([]);
  const [displayProductByCategory, setDisplayProductByCategory] =
    React.useState([]);
  const [product, setProduct] = React.useState([]);

  React.useEffect(() => {
    let post_data = {
      category_id: index,
    };
    get_brand().then((json) => {
      setBrand(json.data);
    });
    get_category().then((json) => {
      setCategory(json.data);
    });
    get_sub_category().then((json) => {
      setSubCategory(json.data);
    });
    get_product().then((json) => {
      setProduct(json.data);
    });
    get_subcategory_by_category(post_data).then((json) => {
      setDisplaySubCategory(json.data);
      //   console.log(JSON.stringify(json.data));
    });
    get_product_by_category(post_data).then((json) => {
      setDisplayProductByCategory(json.data);
      //   console.log(JSON.stringify(json.data));
    });
    get_recommended_product_web().then((json) => {
      setRecommend(json.data);
    });
  }, []);

  useEffect(() => {
    let post_data = {
      category_id: index,
    };
    get_subcategory_by_category(post_data).then((json) => {
      setDisplaySubCategory(json.data);
      //   console.log(JSON.stringify(json.data));
    });
    get_product_by_category(post_data).then((json) => {
      setDisplayProductByCategory(json.data);
      //   alert(JSON.stringify(json.data));
    });
  }, [index]);

  const settings2 = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Header />
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            <div className="listing-accordian">
              {category.map((item) => (
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className={
                        index === item.category_id
                          ? "accordian-parent-active"
                          : "accordian-parent-unactive"
                      }
                      onClick={() => {
                        onChangeIndex(item.category_id);
                      }}
                    >
                      {item.category}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordian-child">
                      {item.sub_category.map((sub) => (
                        <p
                          className={
                            index === sub.sub_category_id
                              ? "p-child-active"
                              : "p-child-unactive"
                          }
                        >
                          {sub.sub_category}
                        </p>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Grid>
          {isMobile ? (
            <Grid item xs={12}>
              <div className="c-brandslider">
                <h3
                  style={{
                    borderBottom: "1px solid #eaeaea",
                    paddingBottom: "1rem",
                  }}
                >
                  Browse By Brands
                </h3>
                <div className="c-slider">
                  <Slider {...settings2}>
                    {brand.map((item) => (
                      <div>
                        <div className="c-theSliderBox">
                          <img src={item.brand_image} />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              {category.map((item) => (
                <>
                  {index === item.category_id && (
                    <>
                      <div className="c-thelistingall">
                        <h3
                          style={{
                            borderBottom: "1px solid #eaeaea",
                            paddingBottom: "1rem",
                          }}
                        >
                          View {item.category}
                        </h3>
                        <div className="c-theItemall">
                          {displayProductByCategory.map((item) => (
                            <div className="c-theListingBox">
                              <img src={item.images} />
                              <p className="listtitle">{item.brand_name}</p>
                              <p className="listsub">{item.product}</p>
                              <p className="listprice">RM {item.price}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </Grid>
          ) : (
            <Grid item xs={10}>
              <div className="c-child-category">
                <div className="row">
                  <Grid container spacing={3}>
                  {displaysubcategory.map((item) => (
                    <Grid item xs={6} sm={3} md={3}>
                      <Link
                        className="the-child-categoryBox"
                        to={`../category/${item.category_id}`}
                      >
                        <div>
                          <img src={item.sub_category_image} />
                          <p>{item.sub_category}</p>
                        </div>
                      </Link>
                    </Grid>
                  ))}
                  </Grid>
                  
                </div>
              </div>
              <div className="c-brandslider">
                <h3
                  style={{
                    borderBottom: "1px solid #eaeaea",
                    paddingBottom: "1rem",
                  }}
                >
                  Browse By Brands
                </h3>
                <div className="c-slider">
                  <Slider {...settings2}>
                    {brand.map((item) => (
                      <div>
                        <div className="c-theSliderBox">
                          <img src={item.brand_image} />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              {category.map((item) => (
                <>
                  {index === item.category_id && (
                    <>
                      <div className="c-thelistingall">
                        <h3
                          style={{
                            borderBottom: "1px solid #eaeaea",
                            paddingBottom: "1rem",
                          }}
                        >
                          View {item.category}
                        </h3>
                        <div className="c-theItemall">
                          {displayProductByCategory.map((item) => (
                            <div className="c-theListingBox">
                              <img src={item.images} />
                              <p className="listtitle">{item.brand_name}</p>
                              <p className="listsub">{item.name}</p>
                              <p className="listprice">RM {item.price}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </Grid>
          )}
        </Grid>
        <div className="c-theRecentlist">
          <div className="theflex">
            <h3>Recently Viewed Products</h3>
            <Link style={{ color: "#000", fontWeight: "700" }}>See More</Link>
          </div>
          <div className="c-theRecentItem">
            {recommend.map((item) => (
              <Link
                className="c-theRecentBox"
                to={`../productdetail/${item.product_id}`}
              >
                <div>
                  <img src={item.images} />
                  <p className="title">{item.brand_name}</p>
                  <p className="sub">{item.name}</p>
                  <p className="price">RM {item.price}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
