import React from "react";
import {
	Container,
	Checkbox,
	Grid,
	Select,
	FormControl,
	MenuItem,
	Modal,
	TextField,
	Typography,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import toast from 'react-simple-toasts';
import {
	FormInputSelect,
} from "./styles";
import useStyles, { PhoneInput } from "./styles";
import { Add, Close, Edit, Remove } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import {
	get_price_type,
	get_installment_period,
	calculate_cart,
	get_delivery_slot,
	get_user_voucher,
	get_state,
	get_city,
	purchase,
} from "../../api/API";

import useCart from "../../hooks/useCart";

import Header from "../../components/Header/Header";


export default function ShoppingCart() {
	const classes = useStyles();
	let { ShoppingCart } = useLocation();
	let history = useHistory();

	const [token, setToken] = React.useState("")

	React.useEffect(() => {
		window.scrollTo(0, 0);
		var token = window.localStorage.getItem("token");
		if (token == "" || token == undefined || token == 'undefined') {
			setToken(token)
		}
	}, [ShoppingCart]);

	const [isLoading, setIsLoading] = React.useState(false);

	const [count, setCount] = React.useState(1);


	const {
		getCart,
		setCartFunction,
		increaseProduct,
		getTotalCart,
		decreaseProduct,
		removeProduct,
		getDeliveryCart,
		clearCart,
		get_by_shipping,
	} = useCart();

	const [cart, setCart] = React.useState();

	const [stateData, setStateData] = React.useState(null);
	const [cityData, setCityData] = React.useState(null);
	const getState = async () => {

		var resState = await get_state({});
		if (resState.status) {
			setStateData(resState.data)
			// setLoading(false);
		}
	}
	const getCity = async (state_id) => {

		var resCity = await get_city({ state_id: state_id });
		if (resCity.status) {
			setCityData(resCity.data)
			// setLoading(false);
		}
	}

	const [cartCount, setCartCount] = React.useState(0);

	const cartItem = window.localStorage.getItem("cart");

	const countCart = JSON.parse(cartItem);
	// console.log(getCart())
	React.useEffect(() => {
		setCart(getCart());
	}, [cartCount, getCart]);

	const removeCartProduct = (index) => {
		removeProduct(index);
		setCartCount(cartCount + 1);
		// window.location.reload();
	};

	const [userData, setUserData] = React.useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		address_1: "",
		postcode: "",
		city: 0,
		state: 0,
	})
	const [selectedDateTime, setSelectedDateTime] = React.useState({
		date: '',
		time: '',
		startTime: '',
		endTime: '',
		dateName: '',
		fullDate: '',
		date_alternative: '',
		time_alternative: '',
		startTime_alternative: '',
		endTime_alternative: '',
		dateName_alternative: '',
		fullDate_alternative: '',
	})
	const [timeSlot, settimeSlot] = React.useState([])
	const [timeSlotAlternative, settimeSlotAlternative] = React.useState([])

	const getDeliveryDateTime = async (dateSelected) => {

		var resDate = await get_delivery_slot({ date: dateSelected });
		if (resDate.status) {
			if (resDate.data.slots != "") {
				console.log('resDate.data.slots[0].delivery_slot_time_id', resDate.data.slots[0].delivery_slot_time_id)
				setSelectedDateTime({
					...selectedDateTime,
					date: resDate.data.delivery_slot_id,
					time: resDate.data.slots[0].delivery_slot_time_id,
					startTime: resDate.data.slots[0].start_time,
					endTime: resDate.data.slots[0].end_time,
					dateName: resDate.data.day,
					fullDate: dateSelected,
				});
				settimeSlot(resDate.data.slots);
			} else {
				settimeSlot([]);
				setSelectedDateTime({
					...selectedDateTime,
					date: '',
					time: '',
					startTime: '',
					endTime: '',
					dateName: resDate.data.day,
					fullDate: dateSelected,
				});
			}
		}
	}
	const getDeliveryDateTimeAlternative = async (dateSelected) => {

		var resDate = await get_delivery_slot({ date: dateSelected });
		if (resDate.status) {
			if (resDate.data.slots != "") {
				setSelectedDateTime({
					...selectedDateTime,
					date_alternative: resDate.data.delivery_slot_id,
					time_alternative: resDate.data.slots[0].delivery_slot_time_id,
					startTime_alternative: resDate.data.slots[0].start_time,
					endTime_alternative: resDate.data.slots[0].end_time,
					dateName_alternative: resDate.data.day,
					fullDate_alternative: dateSelected,
				});
				settimeSlotAlternative(resDate.data.slots);
			} else {
				settimeSlotAlternative([]);
				setSelectedDateTime({
					...selectedDateTime,
					date_alternative: '',
					time_alternative: '',
					startTime_alternative: '',
					endTime_alternative: '',
					dateName_alternative: resDate.data.day,
					fullDate_alternative: dateSelected,
				});
			}
			// else {
			//   Toast.show("No slot availablle", Toast.LONG, Toast.TOP)
			// }
			// setProductVariant(resVariant.data)
		}
	}

	const handleChange = (event) => {
		setUserData({
			...userData,
			[event.target.name]: event.target.value,
		});
		if (event.target.name == 'state') {
			getCity(event.target.value);
		}
	};



	const handleChangeDate = (e) => {
		if (e.target.name == 'date') {
			getDeliveryDateTime(e.target.value)
		} else if (e.target.name == 'time') {
			setSelectedDateTime({
				...selectedDateTime,
				time: e.target.value,
			});
		} else if (e.target.name == 'date2') {
			getDeliveryDateTimeAlternative(e.target.value)
		} else {
			setSelectedDateTime({
				...selectedDateTime,
				time_alternative: e.target.value,
			});
		}
	}

	const [productPriceType, setProductPriceType] = React.useState([])

	const [selectedPriceTypeID, setselectedPriceTypeID] = React.useState()

	const [selectedPriceType, setSelectedPriceType] = React.useState("");

	const [productInstallment, setProductInstallment] = React.useState([])

	const [selectedPriceInstallment, setselectedPriceInstallment] = React.useState('')


	const getPriceType = async () => {

		var resPriceType = await get_price_type({});

		if (resPriceType.status) {
			setProductPriceType(resPriceType.data)
			setselectedPriceTypeID(resPriceType.data[0].price_type_id)
			setSelectedPriceType(resPriceType.data[0].price_type)

			// setLoading(false);
		}

		var resInstallment = await get_installment_period({});
		if (resInstallment.status) {
			setProductInstallment(resInstallment.data)
			setselectedPriceInstallment(resInstallment.data[0].installment_period_id)
		}
	}


	//TOTAL AMOUNT

	const [totalAmount, setTotalAmount] = React.useState(null)

	const calculateCart = () => {

		var tempproducts = [...getCart()];
		console.log(tempproducts)
		var data = {
			product: JSON.stringify(tempproducts),
			payment_method_id: selectedPriceTypeID,
			installment_period_id: selectedPriceInstallment,
		}
		calculate_cart(data).then(res => {
			if (res.status) {
				setTotalAmount(res.total_amount)
			}
		});
	}

	const handleSelect = (index) => {
		// setIsChecked(id);

		var temp_data = [...cart];
		temp_data[index]['check'] = !temp_data[index]['check'];
		setCart(temp_data);
		calculateCart();
	};

	const handleCheck = (check) => {
		setselectedPriceTypeID(check);
	};

	const [voucherData, seVoucherData] = React.useState(null);
	// console.log('getVoucher', getVoucher())
	const getVoucherList = async () => {
		var resVoucher = await get_user_voucher({});
		if (resVoucher.status) {
			seVoucherData(resVoucher.data)
		}
	}

	React.useEffect(() => {
		getPriceType();
		getVoucherList();
		getState();
	}, [])


	React.useEffect(() => {
		calculateCart();
	}, [selectedPriceTypeID, selectedPriceInstallment, cartCount])


	const handleChangeQtyI = (index) => {
		increaseProduct(index)
		setCartCount(cartCount + 1);
	}


	const handleChangeQtyD = (index) => {
		decreaseProduct(index)
		setCartCount(cartCount + 1);
	}

	const handleCheckout = () => {

		var tempproducts = [...cart];
		console.log('handlecheckout', tempproducts)
		if (userData.first_name.length == 0) {
			toast("First Name is required");
			return
		} else if (userData.last_name.length == 0) {
			toast("Last Name is required");
			return
		} else if (userData.phone.length == 0) {
			toast("Contact is required");
			return
		} else if (userData.email.length == 0) {
			toast("Email is required");
			return
		} else if (userData.address_1.length == 0) {
			toast("Address is required");
			return
		} else if (userData.postcode.length == 0) {
			toast("Postcode is required");
			return
		} else if (userData.state == 0) {
			toast("State is required");
			return
		} else if (userData.city == 0) {
			toast("City is required");
			return
		} else {

			var tempproducts = [...cart];
			var product = []
			for (var i = 0; i < tempproducts.length; i++) {
				if (tempproducts[i]['check'] == true) {
					product.push({
						product_id: tempproducts[i].product_id,
						variation_id: tempproducts[i].variation_id,
						product_variation_id: tempproducts[i].product_variation_id,
						quantity: tempproducts[i].quantity,
						add_on: tempproducts[i].add_on,
						variant_selection: tempproducts[i].variant_selection,
						variant: tempproducts[i].variant,
						product: tempproducts[i].product,
						product_image: tempproducts[i].product_image,
						is_installation: tempproducts[i].is_installation,
						total_cash_rate: tempproducts[i].cash_rate,
						total_online_payment_rate: tempproducts[i].online_payment_rate,
						total_installment_rate: tempproducts[i].installment_rate,
						total_installment_rate_2: tempproducts[i].installment_rate_2
						// check: tempproducts[i].check
					})
				}
			}
			console.log(product, 'product')
			var dataArray = {
				...userData,
				payment_method_id: selectedPriceTypeID,
				installment_period_id: selectedPriceInstallment,
				product: JSON.stringify(product),
				delivery_date: selectedDateTime.fullDate,
				time_id: selectedDateTime.time,
				alternative_delivery_date: selectedDateTime.fullDate_alternative,
				alternative_time_id: selectedDateTime.time_alternative,
			}
			console.log(dataArray, 'dataArray', getCart())

			if (token == null) {
				toast('Please Login First');
				return
				// navigation.navigate('LoginScreen')
			} else if (getCart() == '') {
				toast('Please Add Product First');
				return
			} else if (getDeliveryCart() != '') {
				if (
					selectedDateTime.startTime == ''
				) {
					toast("Please select a period");
					return
				} else if (
					selectedDateTime.startTime_alternative == ''
				) {
					toast("Please select a alternative period");
					return
				} else if (
					selectedDateTime.fullDate == selectedDateTime.fullDate_alternative &&
					selectedDateTime.time == selectedDateTime.time_alternative
				) {
					toast("Selected period and alternative period cannot be the same");
					return
				} else {
					// navigation.navigate('CheckoutScreen', dataArray)
					history.push({
						pathname: "/checkout/",
						state: {
							dataArray: dataArray,
							// product: chooseSelectionOption,
							// delivery_type: deliveryOption
						},
						// "/AddOn/" + product_id + '/' + JSON.parse(chooseSelectionOption)
					})
					console.log(dataArray)
				}
			} else {
				history.push({
					pathname: "/checkout/",
					state: {
						dataArray: dataArray,
						// product: chooseSelectionOption,
						// delivery_type: deliveryOption
					},
					// "/AddOn/" + product_id + '/' + JSON.parse(chooseSelectionOption)
				})
			}
		}
	}


	return (
		<React.Fragment>
			<Header />
			<Container>
				<Section>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={5}>
							<Card>
								<Paragraph
									size="500"
									bold="600"
									color="#1e1e1e"
									margin="0 0 1.8rem 0"
								>
									Shipping Address
								</Paragraph>


								<>
									<FormInput
										bg="#fff"
										placeholder="First Name"
										name="first_name"
										label="First Name"
										color="#1e1e1e"
										bold="600"
										onChange={handleChange}
									/>
									<FormInput
										bg="#fff"
										placeholder="Last Name"
										name="last_name"
										label="Last Name"
										color="#1e1e1e"
										bold="600"
										onChange={handleChange}
									/>

									<FormInput
										bg="#fff"
										placeholder="Contact"
										name="phone"
										label="Contact"
										color="#1e1e1e"
										bold="600"
										type='number'
										onChange={handleChange}
									/>

									<FormInput
										bg="#fff"
										placeholder="Email"
										name="email"
										label="Email"
										color="#1e1e1e"
										bold="600"
										onChange={handleChange}
									/>

									<FormInput
										bg="#fff"
										placeholder="Address"
										name="address_1"
										label="Address"
										color="#1e1e1e"
										bold="600"
										multiline
										rows={4}
										onChange={handleChange}
									/>

									<FormInput
										bg="#fff"
										placeholder="Postcode"
										name="postcode"
										label="Postcode"
										color="#1e1e1e"
										bold="600"
										onChange={handleChange}
									/>
									{stateData != null &&

										<Grid item xs={12}>
											<Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >
												State
											</Paragraph>
											<FormInputSelect>
												<Select
													onChange={handleChange}
													displayEmpty
													inputProps={{ "aria-label": "Without label" }}
													name="state"
													value={userData.state}
												>
													<MenuItem disabled value={0}>
														Please Choose a State
													</MenuItem>
													{stateData.map((item) => (
														<MenuItem key={item.state_id} value={item.state_id}>
															{item.state}
														</MenuItem>
													))}
												</Select>
											</FormInputSelect>
										</Grid>
									}
									{cityData != null &&
										<Grid item xs={12}>
											<Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >
												City
											</Paragraph>
											<FormInputSelect>
												<Select
													onChange={handleChange}
													displayEmpty
													inputProps={{ "aria-label": "Without label" }}
													name="city"
													value={userData.city}
												>
													<MenuItem disabled value={0}>
														Please Choose a City
													</MenuItem>
													{cityData.map((item) => (
														<MenuItem key={item.city_id} value={item.city_id}>
															{item.city}
														</MenuItem>
													))}
												</Select>
											</FormInputSelect>
										</Grid>
									}
									{/* <Grid container spacing={2}>
										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="Postcode"
												name="postcode"
												label="Postcode"
												color="#1e1e1e"
												bold="600"
												type="number"
												onChange={handleChange}
											/>
										</Grid>

										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="City"
												name="city"
												label="City"
												color="#1e1e1e"
												bold="600"
												onChange={handleChange}
											/>
										</Grid>
									</Grid>

									<Grid container spacing={2}>
										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="State"
												name="state"
												label="State"
												color="#1e1e1e"
												bold="600"
												onChange={handleChange}
											/>
										</Grid>
									</Grid> */}


									{/* <Grid container spacing={2}>
												<Grid item xs={6}>
												
													<Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >State</Paragraph>
													<FormInputSelect>
														<Select
															onChange={handleChange}
															displayEmpty
															inputProps={{ "aria-label": "Without label" }}
															name="state"
															value={addressData.state}
														>
															{state.map((item) => (
																<MenuItem key={item.name} value={item.name}>
																	{item.name}
																</MenuItem>
															))}
														</Select>
													</FormInputSelect>
												</Grid>
												{instantShippingError && (
													<Grid item xs={12}>
														<p style={{
															color: "red"
														}}>This area is not supported for instant shipping yet</p>
													</Grid>
												)}
                    	</Grid> */}

									{getDeliveryCart() != "" &&
										<>
											<div className={classes.theDivider}></div>
											<br />
											<div className="theSubTitle">
												<Paragraph
													size="500"
													bold="600"
													color="#1e1e1e"
													margin="0 0 1.4rem 0"
												>
													Installation Date & Time
												</Paragraph>
											</div>
											<Grid container spacing={2}>
												<Grid item xs={12}>

													{/* <Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >State</Paragraph> */}
													<FormInput
														bg="#fff"
														name="date"
														color="#1e1e1e"
														bold="600"
														type="date"
														onChange={handleChangeDate}
													/>
												</Grid>
											</Grid>
											<Grid container spacing={2}>
												<Grid item xs={12}>

													{selectedDateTime.time == "" ?
														< FormInputSelect >
															<Select
																displayEmpty
																inputProps={{ "aria-label": "Without label" }}
																value={0}
																disabled
															>
																<MenuItem key={0} value={0}>
																	No Time Slot On This Date
																</MenuItem>

															</Select>
														</FormInputSelect>
														:
														< FormInputSelect >
															<Select
																onChange={handleChangeDate}
																displayEmpty
																inputProps={{ "aria-label": "Without label" }}
																name="time"
																value={selectedDateTime.time}
															>
																{timeSlot.map((item) => (
																	<MenuItem key={item.delivery_slot_time_id} value={item.delivery_slot_time_id}>
																		{item.start_time} - {item.end_time}
																	</MenuItem>
																))}

															</Select>
														</FormInputSelect>
													}
												</Grid>
											</Grid>
											<div className={classes.theDivider}></div>
											<br />
											<div className="theSubTitle">
												<Paragraph
													size="500"
													bold="600"
													color="#1e1e1e"
													margin="0 0 1.4rem 0"
												>
													Alternative Installation Date & Time
												</Paragraph>
											</div>
											<Grid container spacing={2}>
												<Grid item xs={12}>

													{/* <Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >State</Paragraph> */}
													<FormInput
														bg="#fff"
														name="date2"
														color="#1e1e1e"
														bold="600"
														type="date"
														onChange={handleChangeDate}
													/>
												</Grid>
											</Grid>
											<Grid container spacing={2}>
												<Grid item xs={12}>

													{selectedDateTime.time_alternative == "" ?
														< FormInputSelect >
															<Select
																displayEmpty
																inputProps={{ "aria-label": "Without label" }}
																value={0}
																disabled
															>
																<MenuItem key={0} value={0}>
																	No Time Slot On This Date
																</MenuItem>

															</Select>
														</FormInputSelect>
														:
														< FormInputSelect >
															<Select
																onChange={handleChangeDate}
																displayEmpty
																inputProps={{ "aria-label": "Without label" }}
																name="time_alternative"
																value={selectedDateTime.time_alternative}
															>
																{timeSlotAlternative.map((item) => (
																	<MenuItem key={item.delivery_slot_time_id} value={item.delivery_slot_time_id}>
																		{item.start_time} - {item.end_time}
																	</MenuItem>
																))}

															</Select>
														</FormInputSelect>
													}
												</Grid>
											</Grid>
										</>
									}
									{/* <div className={classes.theDivider}></div>
									<br />
									<div className="theSubTitle">
										<Paragraph
											size="500"
											bold="600"
											color="#1e1e1e"
											margin="0 0 1.4rem 0"
										>
											Voucher
										</Paragraph>
									</div>
									<Grid container spacing={2}>
										<Grid item xs={12}>

											<Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >State</Paragraph>

										</Grid>
									</Grid> */}
									<div className={classes.theDivider}></div>
									<br />
									<div className="theSubTitle">
										<Paragraph
											size="500"
											bold="600"
											color="#1e1e1e"
											margin="0 0 1.4rem 0"
										>
											Payment Method
										</Paragraph>
									</div>

									<Grid container spacing={2}>
										<>
											{cart != undefined && cart.length > 0 ? (
												<>
													{productPriceType.map(item => (
														<Grid
															item
															xs={12}
															sm={12}
															md={12}
															onClick={() => handleCheck(item.price_type_id)}
															className={
																selectedPriceTypeID == item.price_type_id
																	? classes.shipping_selection_active
																	: classes.shipping_selection
															}
														>

															{item.price_type}
															{/* <Paragraph style={{ fontSize: '1rem', display: 'block' }} margin="0 0 0 1rem" bold="600"> RM 100.00</Paragraph> */}
														</Grid>
													))}
												</>
											) : null}
										</>



									</Grid>
									<br />
									{selectedPriceTypeID == 2 ? (
										<>
											<Paragraph
												size="500"
												bold="600"
												color="#1e1e1e"
												margin="0 0 1.4rem 0"
											>
												Payment Method
											</Paragraph>

											<Grid container spacing={3}>
												<>
													{productInstallment.map(item => (
														<Grid
															item
															xs={6}

															onClick={() => setselectedPriceInstallment(item.installment_period_id)}
															className={
																selectedPriceInstallment == item.installment_period_id
																	? classes.shipping_selection_active
																	: classes.shipping_selection
															}
														>
															{item.period} months
														</Grid>
													))}
												</>
											</Grid>
										</>

									) : null}



									{/* <Grid container spacing={2}>
										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="Postcode"
												name="postcode"
												label="Postcode"
												color="#1e1e1e"
												bold="600"
												type="number"
												onChange={handleChange}
											/>
										</Grid>

										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="City"
												name="city"
												label="City"
												color="#1e1e1e"
												bold="600"
												onChange={handleChange}
											/>
										</Grid>
									</Grid> */}
								</>

							</Card>
						</Grid>

						<Grid item xs={12} sm={12} md={7}>
							<div className={classes.theTable}>
								<Paragraph
									size="500"
									color="#1e1e1e"
									bold="500"
									margin="1rem 0"
								>
									Product List
								</Paragraph>
								<div className="cart_product_list">
									{cart != undefined && cart.length > 0
										? cart.map((item, index) => (
											<div
												className={classes.theTableList}
											>
												<div className={classes.theFlex}>
													<div className={classes.theFlexInner}>

														<div style={{ padding: "0px 10px 0px 0px" }}>
															<Checkbox
																onChange={() => handleSelect(index)}
																// onChange={handleChange}
																color="primary"
																inputProps={{
																	"aria-label": "secondary checkbox",
																}}
																name="is_active"
																checked={item.check}
															// value={changeText}
															/>
														</div>
														<div className={classes.theProdIMG}>
															<img className={classes.img} src={item.product_image} />
														</div>
														<div className={classes.theDetail}>
															<Paragraph
																size="300"
																color="#1e1e1e"
																bold="600"
																margin="0 0 0.4rem 0"
																className={classes.prod}
															>
																{item.product}
															</Paragraph>

															{/* <div className={classes.theQuantity}>
																<Paragraph
																	size="200"
																	color="#1e1e1e"
																	bold="500"
																	margin="0rem 1rem 0 0"
																>
																	Variant :
																</Paragraph>

																<Paragraph
																	size="300"
																	color="#878787"
																	bold="600"
																>
																	{item.variant}
																</Paragraph>
															</div>

															<div className={classes.theQuantity}>
																<Paragraph
																	size="200"
																	color="#1e1e1e"
																	bold="500"
																	margin="0rem 1rem 0 0"
																>
																	Variant Selection:
																</Paragraph>

																<Paragraph
																	size="300"
																	color="#878787"
																	bold="600"
																>
																	{item.variant_selection}
																</Paragraph>
															</div> */}

															<div className={classes.theQuantity}>
																<Paragraph
																	size="200"
																	color="#1e1e1e"
																	bold="500"
																	margin="0rem 1rem 0 0"
																>
																	Price :
																</Paragraph>
																{selectedPriceTypeID == 1 ?
																	<Paragraph
																		size="300"
																		color="#878787"
																		bold="600"
																	>
																		RM {item.total_cash_rate}
																	</Paragraph>
																	:
																	null
																}
																{selectedPriceTypeID == 2 ?
																	<>
																		{selectedPriceInstallment == 1 ?
																			<Paragraph
																				size="300"
																				color="#878787"
																				bold="600"
																			>
																				RM {item.total_installment_rate}
																			</Paragraph>
																			:
																			<Paragraph
																				size="300"
																				color="#878787"
																				bold="600"
																			>
																				RM {item.total_installment_rate_2}
																			</Paragraph>
																		}
																	</>
																	:
																	null
																}

																{selectedPriceTypeID == 3 ?
																	<Paragraph
																		size="300"
																		color="#878787"
																		bold="600"
																	>
																		RM {item.total_online_payment_rate}
																	</Paragraph>
																	:
																	null
																}
															</div>

															<div className="theFlex_" style={{ alignItems: "center" }}>
																{/* <p className="label">Quantity</p> */}
																<div className="theQuantityContainer">
																	<div
																		className="theIncrement"
																		onClick={() => handleChangeQtyD(item.index)}
																	>
																		<Remove />
																	</div>
																	<p className="theCount">{item.quantity}</p>
																	<div
																		className="theIncrement"
																		onClick={() => handleChangeQtyI(item.index)}
																	>
																		<Add />
																	</div>
																</div>
															</div>

														</div>
														<div
															className="theRemoveItem"
															onClick={() => removeCartProduct(item.index)}
														>
															<Close className={classes.theCloseIcon} />
														</div>
													</div>
													{item.hasOwnProperty('add_on') ?
														<div className={classes.theFlexAddon}>
															<Paragraph
																size="300"
																color="#1e1e1e"
																bold="500"
																margin="1rem 0"
															>
																Add on deals
															</Paragraph>

															{item.add_on.map((itemAdd) => (
																<div className={classes.theFlexAddonInner}>
																	<div className={classes.theProdIMG}>
																		<img className={classes.img} src={itemAdd.image} />
																	</div>
																	<div className={classes.theDetail}>
																		<Paragraph
																			size="300"
																			color="#1e1e1e"
																			bold="600"
																			margin="0 0 0.4rem 0"
																			className={classes.prod}
																		>
																			{itemAdd.name}
																		</Paragraph>

																		<div className={classes.theQuantity}>
																			<Paragraph
																				size="200"
																				color="#1e1e1e"
																				bold="500"
																				margin="0rem 1rem 0 0"
																			>
																				Price :
																			</Paragraph>

																			<Paragraph
																				size="300"
																				color="#878787"
																				bold="600"
																			>
																				{itemAdd.total_price}
																			</Paragraph>
																		</div>

																		<div className={classes.theQuantity}>
																			<Paragraph
																				size="200"
																				color="#1e1e1e"
																				bold="500"
																				margin="0rem 1rem 0 0"
																			>
																				Quantity :
																			</Paragraph>

																			<Paragraph
																				size="300"
																				color="#1e1e1e"
																				bold="600"
																			>
																				{itemAdd.quantity}
																			</Paragraph>
																		</div>
																	</div>
																</div>
															))}
														</div>
														:
														null
													}
												</div>
											</div>
										))
										: null}
								</div>

								<div className={classes.theCalculate}>
									{/* <Paragraph
										size="500"
										bold="600"
										color="#1e1e1e"
										margin="1rem 0"
									>
										Checkout Detail
									</Paragraph> */}
									<br />
									<div className={classes.theFlexCalculate}>
										<Paragraph size="500" color="#1e1e1e">
											SubTotal
										</Paragraph>
										{selectedPriceTypeID == 1 || selectedPriceTypeID == 3 ?
											<Paragraph size="500" color="#1e1e1e" bold="500">
												RM {totalAmount != null && totalAmount.toFixed(2)}
											</Paragraph>
											:
											null
										}
										{selectedPriceTypeID == 2 ?
											<>
												{selectedPriceInstallment == 1 ?
													<Paragraph size="500" color="#1e1e1e" bold="500">
														RM {totalAmount != null && (totalAmount / 12).toFixed(2)} / months
													</Paragraph>
													:
													<Paragraph size="500" color="#1e1e1e" bold="500">
														RM {totalAmount != null && (totalAmount / 24).toFixed(2)} / months
													</Paragraph>
												}
											</>
											:
											null
										}
									</div>

									{/* <div className={classes.theFlexCalculate}>
                    <Paragraph size="500" color="#1e1e1e">
                      Delivery Fee
                    </Paragraph>
                    <Paragraph size="500" color="#1e1e1e" bold="500">
                      RM{" "}
                      {shippingPriceSelection[shippingType] ? (
                        <>
                          {parseFloat(
                            shippingPriceSelection[shippingType]
                          ).toFixed(2)}{" "}
                        </>
                      ) : (
                        "0.00"
                      )}
                    </Paragraph>
                  </div> */}

									{/* {promoCodePrice > 0 && (
                    <div className={classes.theFlexCalculate}>
                      <Paragraph size="500" color="#1e1e1e">
                        Discount
                      </Paragraph>
                      <Paragraph size="500" color="#1e1e1e" bold="500">
                        RM {promoCodePrice}
                      </Paragraph>
                    </div>
                  )} */}

									<br />
									<div className={classes.theFlexCalculate}>
										<Paragraph size="500" bold="600" color="#1e1e1e">
											Total Price
										</Paragraph>
										{selectedPriceTypeID == 1 || selectedPriceTypeID == 3 ?
											<Paragraph size="500" bold="600" color="#7f222c">
												RM {totalAmount != null && totalAmount.toFixed(2)}
											</Paragraph>
											:
											null
										}
										{selectedPriceTypeID == 2 ?
											<>
												{selectedPriceInstallment == 1 ?
													<Paragraph size="500" bold="600" color="#7f222c">
														RM {totalAmount != null && (totalAmount / 12).toFixed(2)} / months
													</Paragraph>
													:
													<Paragraph size="500" bold="600" color="#7f222c">
														RM {totalAmount != null && (totalAmount / 24).toFixed(2)} / months
													</Paragraph>
												}
											</>
											:
											null
										}
									</div>
								</div>
								{!isLoading ? (
									<>
										<div className={classes.theCheckout}>
											<Button
												label="Checkout"
												bold="600"
												bg="#869623"
												color="#fff"
												onClick={() => handleCheckout()}
											/>
										</div>
									</>
								) : (
									<div style={{
										textAlign: "center"
									}}>
										<CircularProgress style={{

											textAlign: "center"
										}}></CircularProgress>
									</div>
								)}
							</div>
						</Grid>
					</Grid>
				</Section>
			</Container>

		</React.Fragment >
	);
}
