import React from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import {
    Container,
    Grid,
    TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const AdminFee = () => {


    let { AdminFee } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [AdminFee]);

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="static_pages">
                    <div className="mobile_container page_padding">
                        <h5 className="h_font_1"><i>Admin Fee</i></h5>

                        {/* <h5 className="h_font_1">Pick Up Service</h5> */}
                        <p className="p_font_New2">
                            By using the IPP and/or BNPL service, you agree that you will be subject to all prevailing statutory taxes, duties, fees, charges and/or costs however denominated as maybe in force and in connection with any future taxes that may be introduced at any point of time. Any changes to the fees and charges shall be communicated to you with sufficient notice. Due to the backend efforts in managing credit planning, we levy an administrative fee to any purchases that are made with the IPP mode of payment for 12 months period and above. IPP for 6 months and below will be waived of such fee; such fee shall vary depending on the payment tenure of IPP and/or BNPL, subject to the decision of Easysettle from time to time.
                        </p>

                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment >
    );
};

export default AdminFee;