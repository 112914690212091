import React from "react";
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Close } from "@material-ui/icons";
import "./OrderDetail.css";

import avatar from "../../assets/images/avantar.png";
import coin from "../../assets/images/coin.svg";
import point from "../../assets/images/point.svg";
import prod001 from "../../assets/images/wall-mounted.png";

import { get_orderhistory_detail } from "../../api/API";

export default function OrderDetail() {
  let { orders_id } = useParams();

  let { OrderDetail } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [OrderDetail]);

  const history = useHistory();

  // GET ORDER HISTORY DETAIL

  const [order, setOrder] = React.useState({});
  const [orderProduct, setOrderProduct] = React.useState(null);

  React.useEffect(() => {
    let postparam = {
      orders_id: orders_id,
    };
    get_orderhistory_detail(postparam).then((json) => {
      setOrder(json.data[0]);
      setOrderProduct(json.data[0].product);
      // console.log()

    });
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theOrderDetailSection">
          <div className="theCardCon">
            <div className="theCardConBody">
              <div className="c-theTableOrder">
                <p className="theOrderNumber">ORDER NUMBER: {order.orders_id}</p>
                <div className="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Product Detail</th>
                        <th width="20%" style={{ textAlign: "center" }}>
                          Variant
                        </th>
                        <th width="10%" style={{ textAlign: "center" }}>
                          Quantity
                        </th>
                        <th width="20%" style={{ textAlign: "center" }}>
                          Single Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderProduct != null && orderProduct.map(item => (
                        <tr>
                          <td>
                            <div className="theFlex">
                              <div className="theProdIMG">
                                <img src={item.product_image} />
                              </div>
                              <div className="theDetail">
                                <p>{item.product}</p>
                              </div>
                            </div>
                          </td>
                          <td>{item.variant} - {item.variant_selection}</td>
                          <td>
                            <div className="quantity">{item.quantity}</div>
                          </td>
                          {order.price_type_id == 1 ?
                            <td>RM {item.total_cash_rate}</td>
                            :
                            null
                          }
                          {order.price_type_id == 2 ?
                            <>
                              {order.installment_period_id == 1 ?
                                <td>RM{item.total_installment_rate} / month</td>
                                :
                                <td>{item.total_installment_rate_2} / month</td>
                              }
                            </>
                            :
                            null
                          }
                          {order.price_type_id == 3 ?
                            <>RM {item.total_online_payment_rate}</>
                            :
                            <></>
                          }
                        </tr>
                      ))}


                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div className="thePaymentAction">
                <div className="thePaypal">
                  <Link className="thePaypalBtn">Pay with Paypal</Link>
                </div>
                <p className="bold">OR</p>
                <div className="theFormGroup">
                  <p className="label">Upload Receipt</p>
                  <TextField className="theFormControl" type="file" />
                  <p className="alert"></p>
                </div>
              </div> */}
              <div className="theTotalPrice">
                <p>
                  Total Order Amount: <span> RM {order.total_amount}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
