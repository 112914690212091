import React, { useState } from "react";
import {
	Container,
	Checkbox,
	Grid,
	Select,
	FormControl,
	MenuItem,
	Modal,
	TextField,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import Section from "../../components/Section/Section";
import FormInput from "../../components/FormInput/FormInput";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import { Button } from "../../components/Button/Button";
import toast from 'react-simple-toasts';
import {
	FormInputSelect,
} from "./styles";
import useStyles, { PhoneInput } from "./styles";
import { Add, Close, Edit, Remove } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import {
	get_price_type,
	get_installment_period,
	calculate_checkout,
	purchase,
	get_state,
	get_city,
} from "../../api/API";

import useCart from "../../hooks/useCart";

import Header from "../../components/Header/Header";


export default function Checkout(props) {
	const classes = useStyles();
	let { Checkout } = useLocation();
	console.log('props.location.state', props.location.state.dataArray.product)
	const [cartData, setCartData] = React.useState(props.location.state.dataArray)
	const [cartProductData, setCartProductData] = React.useState(JSON.parse(props.location.state.dataArray.product))
	const [userData, setUserData] = React.useState(null)
	const [token, setToken] = React.useState("")

	const [stateData, setStateData] = React.useState(null);
	const [cityData, setCityData] = React.useState(null);
	const getState = async () => {

		var resState = await get_state({});
		if (resState.status) {
			setStateData(resState.data)
			// setLoading(false);
			getCity(userData != null ? userData.state : 0);
		}
	}
	const getCity = async (state_id) => {

		var resCity = await get_city({ state_id: state_id });
		if (resCity.status) {
			setCityData(resCity.data)
			// setLoading(false);
		}
	}

	React.useEffect(() => {
		window.scrollTo(0, 0);
		var token = window.localStorage.getItem("token");
		if (token == "" || token == undefined || token == 'undefined') {
			setToken(token)
		}
		setUserData(props.location.state.dataArray)
	}, [Checkout]);

	React.useEffect(() => {
		getState();
	}, [userData]);

	console.log('checkout ini data', userData)

	const [isLoading, setIsLoading] = React.useState(false);

	const [count, setCount] = React.useState(1);


	const {
		getCart,
		setCartFunction,
		increaseProduct,
		getTotalCart,
		decreaseProduct,
		removeProduct,
		clearCart,
		get_by_shipping,
	} = useCart();

	const [cartCount, setCartCount] = React.useState(0);

	const cartItem = window.localStorage.getItem("cart");

	const countCart = JSON.parse(cartItem);

	const handleChange = (event) => {
		setUserData({
			...userData,
			[event.target.name]: event.target.value,
		});
	};



	const [productPriceType, setProductPriceType] = React.useState([])

	const [selectedPriceTypeID, setselectedPriceTypeID] = React.useState()

	const [selectedPriceType, setSelectedPriceType] = React.useState("");

	const [productInstallment, setProductInstallment] = React.useState([])

	const [selectedPriceInstallment, setselectedPriceInstallment] = React.useState('')


	const getPriceType = async () => {

		var resPriceType = await get_price_type({});

		if (resPriceType.status) {
			setProductPriceType(resPriceType.data)
			setselectedPriceTypeID(resPriceType.data[0].price_type_id)
			setSelectedPriceType(resPriceType.data[0].price_type)

			// setLoading(false);
		}

		var resInstallment = await get_installment_period({});
		if (resInstallment.status) {
			setProductInstallment(resInstallment.data)
			setselectedPriceInstallment(resInstallment.data[0].installment_period_id)
		}
	}


	//TOTAL AMOUNT

	const [totalAmount, setTotalAmount] = React.useState(null)

	const calculateCheckout = () => {
		setIsLoading(true);
		var data = {
			...props.location.state.dataArray,
			payment_method_id: selectedPriceTypeID,
			installment_period_id: selectedPriceInstallment
		}
		calculate_checkout(data).then(res => {
			if (res.status) {
				setCartProductData(res.products)
				setTotalAmount(res.final_amount)
				setIsLoading(false);
				// setData(props.location.state)
			}
		});
	}


	const handleCheck = (check) => {
		console.log('handleCheck', check)
		setselectedPriceTypeID(check);
	};

	React.useEffect(() => {
		getPriceType();
	}, [])


	React.useEffect(() => {
		calculateCheckout();
	}, [selectedPriceTypeID, selectedPriceInstallment])


	const handleCheckout = () => {
		var data = {
			...cartData,
			product: JSON.stringify(cartProductData),
			payment_method_id: selectedPriceTypeID,
			installment_period_id: selectedPriceInstallment,
			delivery_date: cartData.delivery_date != '' ? cartData.delivery_date : null,
			time_id: cartData.time_id != '' ? cartData.time_id : null,
			alternative_delivery_date: cartData.alternative_delivery_date != '' ? cartData.alternative_delivery_date : null,
			alternative_time_id: cartData.alternative_time_id != '' ? cartData.alternative_time_id : null
		}

		purchase(data).then(json => {
			if (json.status) {
				// clearCart();
				props.history.push("/orderdetail/" + json.data);
				toast("Purchased");
			}
		})
	}


	return (
		<React.Fragment>
			<Header />
			<Container>
				<Section>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={5}>
							<Card>
								<Paragraph
									size="500"
									bold="600"
									color="#1e1e1e"
									margin="0 0 1.8rem 0"
								>
									Shipping Address
								</Paragraph>

								{userData != null &&
									<>
										<FormInput
											placeholder="First Name"
											name="first_name"
											label="First Name"
											color="#1e1e1e"
											bold="600"
											onChange={handleChange}
											disabled
											bg="#eeeeee"
											value={userData.first_name}
										/>
										<FormInput
											placeholder="Last Name"
											name="last_name"
											label="Last Name"
											color="#1e1e1e"
											bold="600"
											onChange={handleChange}
											disabled
											bg="#eeeeee"
											value={userData.last_name}
										/>

										<FormInput
											placeholder="Contact"
											name="phone"
											label="Contact"
											color="#1e1e1e"
											bold="600"
											type='number'
											onChange={handleChange}
											disabled
											bg="#eeeeee"
											value={userData.phone}
										/>

										<FormInput
											placeholder="Email"
											name="email"
											label="Email"
											color="#1e1e1e"
											bold="600"
											onChange={handleChange}
											disabled
											bg="#eeeeee"
											value={userData.email}
										/>


										<FormInput
											placeholder="Address"
											name="address_1"
											label="Address"
											color="#1e1e1e"
											bold="600"
											onChange={handleChange}
											disabled
											bg="#eeeeee"
											multiline
											rows={4}
											value={userData.address_1}
										/>

										<FormInput
											placeholder="Postcode"
											name="postcode"
											label="Postcode"
											color="#1e1e1e"
											bold="600"
											onChange={handleChange}
											disabled
											bg="#eeeeee"
											value={userData.postcode}
										/>
										{stateData != null &&

											<Grid item xs={12}>
												<Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >
													State
												</Paragraph>
												<FormInputSelect
													style={{ backgroundColor: '#eeeeee' }}
												>
													<Select
														disabled
														onChange={handleChange}
														displayEmpty
														inputProps={{ "aria-label": "Without label" }}
														name="state"
														value={userData.state}
													>
														<MenuItem disabled value={0}>
															Please Choose a State
														</MenuItem>
														{stateData.map((item) => (
															<MenuItem key={item.state_id} value={item.state_id}>
																{item.state}
															</MenuItem>
														))}
													</Select>
												</FormInputSelect>
											</Grid>
										}
										{cityData != null &&
											<Grid item xs={12}>
												<Paragraph style={{ fontSize: "1rem", marginBottom: "1rem" }} color="#495057" bold="600" >
													City
												</Paragraph>
												<FormInputSelect
													style={{ backgroundColor: '#eeeeee' }}
												>
													<Select
														onChange={handleChange}
														displayEmpty
														inputProps={{ "aria-label": "Without label" }}
														name="city"
														disabled
														value={userData.city}
													>
														<MenuItem disabled value={0}>
															Please Choose a City
														</MenuItem>
														{cityData.map((item) => (
															<MenuItem key={item.city_id} value={item.city_id}>
																{item.city}
															</MenuItem>
														))}
													</Select>
												</FormInputSelect>
											</Grid>
										}
										<div className={classes.theDivider}></div>
										<br />
										<div className="theSubTitle">
											<Paragraph
												size="500"
												bold="600"
												color="#1e1e1e"
												margin="0 0 1.4rem 0"
											>
												Payment Method
											</Paragraph>
										</div>

										<Grid container spacing={2}>
											<>
												{cartData != undefined && cartProductData.length > 0 ? (
													<>
														{productPriceType.map(item => (
															<Grid
																item
																xs={12}
																sm={12}
																md={12}
																onClick={() => handleCheck(item.price_type_id)}
																className={
																	selectedPriceTypeID == item.price_type_id
																		? classes.shipping_selection_active
																		: classes.shipping_selection
																}
															>

																{item.price_type}
																{/* <Paragraph style={{ fontSize: '1rem', display: 'block' }} margin="0 0 0 1rem" bold="600"> RM 100.00</Paragraph> */}
															</Grid>
														))}
													</>
												) : null}
											</>



										</Grid>
										<br />
										{selectedPriceTypeID == 2 ? (
											<>
												<Paragraph
													size="500"
													bold="600"
													color="#1e1e1e"
													margin="0 0 1.4rem 0"
												>
													Payment Method
												</Paragraph>

												<Grid container spacing={3}>
													<>
														{productInstallment.map(item => (
															<Grid
																item
																xs={6}

																onClick={() => setselectedPriceInstallment(item.installment_period_id)}
																className={
																	selectedPriceInstallment == item.installment_period_id
																		? classes.shipping_selection_active
																		: classes.shipping_selection
																}
															>
																{item.period} months
															</Grid>
														))}
													</>
												</Grid>
											</>

										) : null}



										{/* <Grid container spacing={2}>
										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="Postcode"
												name="postcode"
												label="Postcode"
												color="#1e1e1e"
												bold="600"
												type="number"
												onChange={handleChange}
											/>
										</Grid>

										<Grid item xs={6}>
											<FormInput
												bg="#fff"
												placeholder="City"
												name="city"
												label="City"
												color="#1e1e1e"
												bold="600"
												onChange={handleChange}
											/>
										</Grid>
									</Grid> */}
									</>
								}
							</Card>
						</Grid>

						<Grid item xs={12} sm={12} md={7}>
							<div className={classes.theTable}>
								<Paragraph
									size="500"
									color="#1e1e1e"
									bold="500"
									margin="1rem 0"
								>
									Product List
								</Paragraph>
								<div className="cart_product_list">
									{cartProductData != undefined && cartProductData.length > 0
										? cartProductData.map((item) => (
											<div
												className={classes.theTableList}
											>
												<div className={classes.theFlex}>
													<div className={classes.theFlexInner}>
														<div className={classes.theProdIMG}>
															<img className={classes.img} src={item.product_image} />
														</div>
														<div className={classes.theDetail}>
															<Paragraph
																size="300"
																color="#1e1e1e"
																bold="600"
																margin="0 0 0.4rem 0"
																className={classes.prod}
															>
																{item.product}
															</Paragraph>

															<div className={classes.theQuantity}>
																<Paragraph
																	size="200"
																	color="#1e1e1e"
																	bold="500"
																	margin="0rem 1rem 0 0"
																>
																	Variant :
																</Paragraph>

																<Paragraph
																	size="300"
																	color="#878787"
																	bold="600"
																>
																	{item.variant}
																</Paragraph>
															</div>

															<div className={classes.theQuantity}>
																<Paragraph
																	size="200"
																	color="#1e1e1e"
																	bold="500"
																	margin="0rem 1rem 0 0"
																>
																	Variant Selection:
																</Paragraph>

																<Paragraph
																	size="300"
																	color="#878787"
																	bold="600"
																>
																	{item.variant_selection}
																</Paragraph>
															</div>

															<div className={classes.theQuantity}>
																<Paragraph
																	size="200"
																	color="#1e1e1e"
																	bold="500"
																	margin="0rem 1rem 0 0"
																>
																	Quantity :
																</Paragraph>

																{count == 0 ? (

																	<p>0</p>
																) : (
																	<Paragraph
																		size="300"
																		color="#1e1e1e"
																		bold="600"
																	>
																		{item.quantity}
																	</Paragraph>

																)}
															</div>

															<div className={classes.theQuantity}>
																<Paragraph
																	size="200"
																	color="#1e1e1e"
																	bold="500"
																	margin="0rem 1rem 0 0"
																>
																	Price :
																</Paragraph>
																{selectedPriceTypeID == 1 ?
																	<Paragraph
																		size="300"
																		color="#878787"
																		bold="600"
																	>
																		RM {item.total_cash_rate}
																	</Paragraph>
																	:
																	null
																}
																{selectedPriceTypeID == 2 ?
																	<>
																		{selectedPriceInstallment == 1 ?
																			<Paragraph
																				size="300"
																				color="#878787"
																				bold="600"
																			>
																				RM {item.total_installment_rate}
																			</Paragraph>
																			:
																			<Paragraph
																				size="300"
																				color="#878787"
																				bold="600"
																			>
																				RM {item.total_installment_rate_2}
																			</Paragraph>
																		}
																	</>
																	:
																	null
																}

																{selectedPriceTypeID == 3 ?
																	<Paragraph
																		size="300"
																		color="#878787"
																		bold="600"
																	>
																		RM {item.total_online_payment_rate}
																	</Paragraph>
																	:
																	null
																}
															</div>

														</div>
													</div>
													{item.hasOwnProperty('add_on') ?
														<div className={classes.theFlexAddon}>
															<Paragraph
																size="300"
																color="#1e1e1e"
																bold="500"
																margin="1rem 0"
															>
																Add on deals
															</Paragraph>

															{item.add_on.map((itemAdd) => (
																<div className={classes.theFlexAddonInner}>
																	<div className={classes.theProdIMG}>
																		<img className={classes.img} src={itemAdd.image} />
																	</div>
																	<div className={classes.theDetail}>
																		<Paragraph
																			size="300"
																			color="#1e1e1e"
																			bold="600"
																			margin="0 0 0.4rem 0"
																			className={classes.prod}
																		>
																			{itemAdd.name}
																		</Paragraph>

																		<div className={classes.theQuantity}>
																			<Paragraph
																				size="200"
																				color="#1e1e1e"
																				bold="500"
																				margin="0rem 1rem 0 0"
																			>
																				Price :
																			</Paragraph>

																			<Paragraph
																				size="300"
																				color="#878787"
																				bold="600"
																			>
																				{itemAdd.total_price}
																			</Paragraph>
																		</div>

																		<div className={classes.theQuantity}>
																			<Paragraph
																				size="200"
																				color="#1e1e1e"
																				bold="500"
																				margin="0rem 1rem 0 0"
																			>
																				Quantity :
																			</Paragraph>

																			<Paragraph
																				size="300"
																				color="#1e1e1e"
																				bold="600"
																			>
																				{itemAdd.quantity}
																			</Paragraph>
																		</div>
																	</div>
																</div>
															))}
														</div>
														:
														null
													}
												</div>
											</div>
										))
										: null}
								</div>

								<div className={classes.theCalculate}>
									{/* <Paragraph
										size="500"
										bold="600"
										color="#1e1e1e"
										margin="1rem 0"
									>
										Checkout Detail
									</Paragraph> */}
									<br />
									<div className={classes.theFlexCalculate}>
										<Paragraph size="500" color="#1e1e1e">
											SubTotal
										</Paragraph>
										{selectedPriceTypeID == 1 || selectedPriceTypeID == 3 ?
											<Paragraph size="500" color="#1e1e1e" bold="500">
												RM {totalAmount != null && totalAmount.toFixed(2)}
											</Paragraph>
											:
											null
										}
										{selectedPriceTypeID == 2 ?
											<>
												{selectedPriceInstallment == 1 ?
													<Paragraph size="500" color="#1e1e1e" bold="500">
														RM {totalAmount != null && (totalAmount / 12).toFixed(2)} / months
													</Paragraph>
													:
													<Paragraph size="500" color="#1e1e1e" bold="500">
														RM {totalAmount != null && (totalAmount / 24).toFixed(2)} / months
													</Paragraph>
												}
											</>
											:
											null
										}
									</div>

									{/* <div className={classes.theFlexCalculate}>
                    <Paragraph size="500" color="#1e1e1e">
                      Delivery Fee
                    </Paragraph>
                    <Paragraph size="500" color="#1e1e1e" bold="500">
                      RM{" "}
                      {shippingPriceSelection[shippingType] ? (
                        <>
                          {parseFloat(
                            shippingPriceSelection[shippingType]
                          ).toFixed(2)}{" "}
                        </>
                      ) : (
                        "0.00"
                      )}
                    </Paragraph>
                  </div> */}

									{/* {promoCodePrice > 0 && (
                    <div className={classes.theFlexCalculate}>
                      <Paragraph size="500" color="#1e1e1e">
                        Discount
                      </Paragraph>
                      <Paragraph size="500" color="#1e1e1e" bold="500">
                        RM {promoCodePrice}
                      </Paragraph>
                    </div>
                  )} */}

									<br />
									<div className={classes.theFlexCalculate}>
										<Paragraph size="500" bold="600" color="#1e1e1e">
											Total Price
										</Paragraph>
										{selectedPriceTypeID == 1 || selectedPriceTypeID == 3 ?
											<Paragraph size="500" bold="600" color="#7f222c">
												RM {totalAmount != null && totalAmount.toFixed(2)}
											</Paragraph>
											:
											null
										}
										{selectedPriceTypeID == 2 ?
											<>
												{selectedPriceInstallment == 1 ?
													<Paragraph size="500" bold="600" color="#7f222c">
														RM {totalAmount != null && (totalAmount / 12).toFixed(2)} / months
													</Paragraph>
													:
													<Paragraph size="500" bold="600" color="#7f222c">
														RM {totalAmount != null && (totalAmount / 24).toFixed(2)} / months
													</Paragraph>
												}
											</>
											:
											null
										}
									</div>
								</div>
								{!isLoading ? (
									<>
										<div className={classes.theCheckout}>
											<Button
												label="Checkout"
												bold="600"
												bg="#869623"
												color="#fff"
												onClick={() => handleCheckout()}
											/>
										</div>
									</>
								) : (
									<div style={{
										textAlign: "center"
									}}>
										<CircularProgress style={{

											textAlign: "center"
										}}></CircularProgress>
									</div>
								)}
							</div>
						</Grid>
					</Grid>
				</Section>
			</Container>

		</React.Fragment >
	);
}
