import React from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import product001 from "../../assets/images/wall-mounted.png";
import point from "../../assets/images/point.svg";

import "./PointReward.css";

export default function PointReward() {
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theSectionReward">
          <div className="theflex">
            <h3>Coin Reward</h3>
            <Link to="" style={{ color: "#000", fontWeight: "700" }}>
              See More
            </Link>
          </div>
          <div className="c-theItem">
            <Link className="c-theSliderBox" to="">
              <div>
                <img src={product001} />
                <p className="title">Hisense</p>
                <p className="sub">Eco- Friendly Aircon</p>
                <div className="theFlex">
                  <div className="theIcon">
                    <img src={point} />
                  </div>
                  <p className="price">2000 Coins</p>
                </div>
              </div>
            </Link>

            <Link className="c-theSliderBox" to="">
              <div className="theOverlay">
                <div className="theNoEnough">
                  <p>NO ENOUGHT COINS !</p>
                </div>
              </div>
              <div>
                <img src={product001} />
                <p className="title">Hisense</p>
                <p className="sub">Eco- Friendly Aircon</p>
                <div className="theFlex">
                  <div className="theIcon">
                    <img src={point} />
                  </div>
                  <p className="price">2000 Coins</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
