import React from 'react';
import './styles.css';
import { useLocation } from "react-router-dom";
import {
    Container,
    Grid,
    TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const CancelPolicy = () => {


    let { CancelPolicy } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [CancelPolicy]);

    return (
        <React.Fragment>
            <Header />
            <Container>
                <div className="static_pages">
                    <div className="mobile_container page_padding">
                        <h5 className="h_font_1"><i>Cancellation Policy</i></h5>

                        {/* <h5 className="h_font_1">Pick Up Service</h5> */}
                        <p className="p_font_New2">
                            Easysettle reserves the right to hold or cancel any order with prior notice when deemed necessary.
                        </p>
                        <p className="p_font_New2">
                            Easysettle will proceed with cancellation should circumstances below occur:
                        </p>
                        <p className="p_font_New2"><li>
                            The item is not / no longer available
                        </li></p>
                        <p className="p_font_New2"><li>
                            Pricing disputes arise
                        </li></p>
                        <p className="p_font_New2"><li>
                            Product illustration differs from actual product
                        </li></p>
                        <p className="p_font_New2"><li>
                            Payment failed or not received
                        </li></p>
                        <p className="p_font_New2"><li>
                            Payment information could not be verified
                        </li></p>
                        <p className="p_font_New2"><li>
                            The order cannot be shipped to the address provided or where the shipping address could not be verified
                        </li></p>
                        <p className="p_font_New2"><li>
                            Violation of Easysettle's Terms and Conditions
                        </li></p>
                        <p className="p_font_New2"><li>
                            Cancellation initiated by the Customer
                        </li></p>
                        <p className="p_font_New2"><li>
                            Any other unforeseen circumstances that lead to cancellation
                        </li></p>
                        <p className="p_font_New2">
                            Upon cancellation, refund will be processed accordingly and might take up to 14 working days.
                        </p>
                    </div>
                </div>
            </Container>
            <Footer />
        </React.Fragment >
    );
};

export default CancelPolicy;