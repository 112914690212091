import React from "react";
import {
  Container,
  Checkbox,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Modal,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Add, ChevronRight, Close, Edit, Delete, Home } from "@material-ui/icons";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import avatar from "../../assets/images/avantar.png";
import coin from "../../assets/images/coin.svg";
import point from "../../assets/images/point.svg";
import "./Address.css";

// import {
//   get_user_address,
//   get_state,
//   user_address,
//   edit_user_address,
//   delete_user_address,
//   get_user_profile,
// } from "../../api/API";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();

  return {
    margin: 0,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function Address() {
  const history = useHistory();
  let { Addresses } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Addresses]);

  let { user_address_id } = useParams();

  // GET PROFILE
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    // let postparam = {
    //   token: token,
    // };
    // get_user_profile(postparam).then((json) => {
    //   setUser(json.data);
    // });
  }, []);

  // GET USER ADDRESS

  const [token, setToken] = React.useState("");
  const [address, setAddress] = React.useState([]);

  React.useEffect(() => {
    // let postparam = {
    //   token: token,
    // };
    // get_user_address(postparam).then((json) => {
    //   setAddress(json.data);
    // });
  }, []);

  // GET STATE

  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    // get_state().then((json) => {
    //   setState(json.data);
    // });
  }, []);

  // MODAL ADD ADDRESS

  const [openaddmodal, setOpenAddModal] = React.useState(false);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  // ADD ADDRESS

  const [addressData, setAddressData] = React.useState({
    address: "",
    postcode: "",
    city: "",
    country: "Malaysia",
    state_id: "",
  });

  const { is_active } = addressData;

  const handleChange = (event) => {
    setAddressData({
      ...addressData,
      [event.target.name]: event.target.value,
    });
    setAddressEditData({
      ...addressEditData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheck = (event) => {
    setAddressData({
      ...addressData,
      [event.target.name]: event.target.checked,
    });
    setEditCheck({ ...editCheck, [event.target.name]: event.target.checked });
  };

  const handleSubmitAddress = () => {
    // let res = "0";
    // if (addressData.is_active == true) {
    //   res = "1";
    // }
    // if (
    //   addressData.address.length == 0 ||
    //   addressData.state_id.length == 0 ||
    //   addressData.postcode.length == 0 ||
    //   addressData.city.length == 0 ||
    //   addressData.country.length == 0
    // ) {
    //   alert("All Field Required");
    // } else {
    //   let postparam = {
    //     ...addressData,
    //     is_active: res,
    //   };
    //   user_address(postparam).then((json) => {
    //     if (json.status) {
    //       alert("Added Successful");
    //     } else {
    //     }
    //   });
    // }
  };

  // MODAL EDIT ADDRESS

  const [openeditmodal, setOpenEditModal] = React.useState(false);
  const [modalID, setModalID] = React.useState(false);
  const [editCheck, setEditCheck] = React.useState(false);

  const handleOpenEditModal = (modalID, event) => {
    setOpenEditModal(true);
    // let postparam = {
    //   token: token,
    //   user_address_id: modalID,
    // };
    // get_user_address(postparam).then((json) => {
    //   setAddressEditData(json.data[0]);
    //   setModalID(modalID);
    //   console.log(modalID);

    //   if (json.data[0].is_active == "1") {
    //     console.log("true", addressEditData);
    //     setEditCheck(true);
    //   } else {
    //     setEditCheck(false);
    //   }
    // });
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  // EDIT ADDRESS

  const [addressEditData, setAddressEditData] = React.useState({});

  React.useEffect(() => {
    getAddressData();
  }, []);

  const getAddressData = (modalID) => {
    // let postparam = {
    //   token: token,
    //   user_address_id: modalID,
    // };
    // get_user_address(postparam).then((json) => {
    //   setAddressEditData(json.data);
    //   console.log(JSON.stringify(json.data));
    // });
  };

  const handeleEditAddress = () => {
    // let res = "0";
    // if (editCheck.is_active == true) {
    //   res = "1";
    // }
    // let postparam = {
    //   ...addressEditData,
    //   is_active: res,
    // };
    // edit_user_address(postparam).then((json) => {
    //   if (json.status) {
    //     alert("Edit Address Successful");
    //   }
    // });
  };

  // DELETE USER ADDRESS

  const [deleteAddress, setDeleteAddress] = React.useState({});

  const handleDeleteAddress = (user_address_id) => {
    // let postparam = {
    //   user_address_id: user_address_id,
    // };
    // delete_user_address(postparam).then((json) => {
    //   if (json.status) {
    //     window.confirm("Are you sure want to delete this address?");
    //     setDeleteAddress(json.data);
    //     window.location.reload();
    //   }
    // });
  };

  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theAddressSection">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={3}>
              <div className="theUserCard">
                <div className="theCardHeader">
                  <div className="theAvatar">
                    {/* <img src={avatar} /> */}
                    <p>Username</p>
                  </div>
                </div>
                <div className="theCardBody">
                  <Link className="theCoin" to="/coinreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={coin} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Coins</p>
                        <p className="coin">9,000 Coins</p>
                      </div>
                    </div>
                  </Link>
                  {/* <Link className="theCoin" to="/pointreward">
                    <div className="theFlex">
                      <div className="theIconIMG">
                        <img src={point} />
                      </div>
                      <div className="theCoinDetail">
                        <p className="title">Easy Points</p>
                        <p className="point">9,000 Points</p>
                      </div>
                    </div>
                  </Link> */}
                  <div class="theLink">
                    <Link className="theNavLink" to="/profile">
                      My Profile
                    </Link>
                    <Link className="theNavLinkActive" to="/address">Address</Link>
                    <Link className="theNavLink" to="/order">Order History</Link>
                    <Link className="theNavLink" to="/referfriend">
                      Refer a Friend
                    </Link>
                    <Link className="theNavLink" to="/becomeconstructor">
                      Become Constructor
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={9}>
              <div className="theCardCon">
                <div className="theCardConHeader">
                  <p>My Address</p>
                  <Link
                    className="theFlexAdd"
                    onClick={() => handleOpenAddModal()}
                  >
                    <Home className="theIcon" />
                    <p>Add New Address</p>
                  </Link>
                </div>
                <div className="theCardConBody">
                  <div className="theForm">
                    <Grid container spacing={3}>
                      {address.map((item) => (
                        <Grid item xs={12} sm={6} md={6}>
                          <div className="theCardAddress">
                            <div className="theFlex">
                              <p className="bold">{item.name}</p>
                              <p className="bold">{item.contact}</p>
                            </div>
                            <p className="address">
                              {item.address}, {item.postcode}, {item.city},{" "}
                              {item.state}, {item.country}
                            </p>
                            {item.is_active == 1 ? (
                              <p className="alert">*Default delivery address</p>
                            ) : (
                              <></>
                            )}
                            <div className="theAction">
                              <Link
                                className="theActionLink"
                                onClick={() =>
                                  handleDeleteAddress(item.user_address_id)
                                }
                              >
                                <Delete className="theIcon" />
                              </Link>
                              <Link
                                className="theActionLink"
                                onClick={() =>
                                  handleOpenEditModal(item.user_address_id)
                                }
                              >
                                <Edit className="theIcon" />
                              </Link>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
      <Modal
        open={openaddmodal}
        onClose={handleCloseAddModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="theModalAddress">
          <div className="theModalHeader">
            <div className="theFlexIcon" onClick={() => handleCloseAddModal()}>
              <Close className="theIcon" />
            </div>
          </div>
          <div className="theModalBody">
            <div className="theForm">
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={6}>
                  <div className="theFormBox">
                    {/* <div className="theFormGroup">
                      <p className="label">Full Name</p>
                      <TextField
                        className="theFormControl"
                        placeholder="Full Name"
                        name="name"
                        onChange={handleChange}
                      />
                    </div> */}
                    <div className="theFormGroup">
                      <p className="label">Address</p>
                      <TextField
                        className="theFormControl"
                        placeholder="Address"
                        name="address"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="theFormGroup">
                      <p className="label">Postcode</p>
                      <TextField
                        className="theFormControl"
                        name="postcode"
                        onChange={handleChange}
                        placeholder="Postcode"
                        type="number"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <div className="theFormBox">
                    {/* <div className="theFormGroup">
                      <p className="label">Phone Number</p>
                      <TextField
                        className="theFormControl"
                        type="number"
                        placeholder="Phone Number"
                      />
                    </div> */}
                    <div className="theFormGroup">
                      <p className="label">City</p>
                      <TextField
                        className="theFormControl"
                        placeholder="City"
                        name="city"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="theFormGroup">
                      <p className="label">State</p>
                      <FormControl className="theFormControl">
                        <Select
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="state_id"
                        >
                          <MenuItem disabled>Select Your State</MenuItem>
                          {state.map((item) => (
                            <MenuItem key={item.state_id} value={item.state_id}>
                              {item.state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="theFormGroup">
                      <div className="theCheckBoxFlex">
                        <Checkbox
                          onChange={handleChangeCheck}
                          // onChange={handleChange}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          name="is_active"
                          checked={is_active}
                          // value={changeText}
                        />
                        <p>Set as default delivery address</p>
                      </div>
                    </div>
                    <div className="theSubmit">
                      <Link
                        className="theSubmitBtn"
                        onClick={() => handleSubmitAddress()}
                      >
                        Submit
                      </Link>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openeditmodal}
        onClose={handleCloseEditModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // setModalID={modalID}
      >
        <div className="theModalAddress">
          <div className="theModalHeader">
            <div className="theFlexIcon" onClick={() => handleCloseEditModal()}>
              <Close className="theIcon" />
            </div>
          </div>
          <div className="theModalBody">
            <div className="theForm">
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={6}>
                  <div className="theFormBox">
                    {/* <div className="theFormGroup">
                      <p className="label">Full Name</p>
                      <TextField
                        className="theFormControl"
                        placeholder="Full Name"
                        name="name"
                        onChange={handleChange}
                      />
                    </div> */}
                    <div className="theFormGroup">
                      <p className="label">Address</p>
                      <TextField
                        className="theFormControl"
                        placeholder="Address"
                        name="address"
                        onChange={handleChange}
                        value={addressEditData.address}
                      />
                    </div>
                    <div className="theFormGroup">
                      <p className="label">Postcode</p>
                      <TextField
                        className="theFormControl"
                        name="postcode"
                        onChange={handleChange}
                        placeholder="Postcode"
                        type="number"
                        value={addressEditData.postcode}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <div className="theFormBox">
                    {/* <div className="theFormGroup">
                      <p className="label">Phone Number</p>
                      <TextField
                        className="theFormControl"
                        type="number"
                        placeholder="Phone Number"
                      />
                    </div> */}
                    <div className="theFormGroup">
                      <p className="label">City</p>
                      <TextField
                        className="theFormControl"
                        placeholder="City"
                        name="city"
                        onChange={handleChange}
                        value={addressEditData.city}
                      />
                    </div>
                    <div className="theFormGroup">
                      <p className="label">State</p>
                      <FormControl className="theFormControl">
                        <Select
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="state_id"
                          value={addressEditData.state_id || ""}
                        >
                          <MenuItem disabled>Select Your State</MenuItem>
                          {state.map((item) => (
                            <MenuItem key={item.state_id} value={item.state_id}>
                              {item.state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="theFormGroup">
                      <div className="theCheckBoxFlex">
                        <Checkbox
                          onChange={handleChangeCheck}
                          // onChange={handleChange}
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                          name="is_active"
                          checked={editCheck}
                          value={addressEditData.is_active}
                        />
                        <p>{addressEditData.is_active}</p>
                        <p>Set as default delivery address</p>
                      </div>
                    </div>
                    <div className="theSubmit">
                      <Link
                        className="theSubmitBtn"
                        onClick={() => handeleEditAddress()}
                      >
                        Submit
                      </Link>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
