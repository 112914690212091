import React from "react";
import {
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CheckIcon from "@material-ui/icons/Check";
import "./SignUp.css";
import { Link, useLocation, useHistory } from "react-router-dom";

import { login, register } from "../../api/API";

export default function SignUp() {
  const history = useHistory();
  let { SignUp } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [SignUp]);
  const [state, setState] = React.useState({
    checkedB: false,
  });
  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  //Login
  const [loginData, setLoginData] = React.useState({
    username: "",
    password: "",
    token: "",
  });

  const handleChange = (event) => {
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = () => {
    if (loginData.email.length == 0 || loginData.password.length == 0) {
      alert("Username or password field cannot be empty");
    }
    login(loginData).then((json) => {
      if (json.status) {
        window.localStorage.setItem("token", json.data.token);
        history.push("/");
        window.location.reload();
      } else {
        // alert("Username or password incorrect")
      }
    });
  };

  // Register
  const [registerData, setRegisterData] = React.useState({
      email: ""
  })
  const handleChangeRegister = event => {
    setRegisterData({
        ...registerData,
        [event.target.name]: event.target.value
    });
}

const handleRegister = () => {
    register(registerData).then((json)=>{
        if(json.status){
            // history.push("/")
            console.log(JSON.stringify(json))
        }else{
        }
    })
}
  return (
    <React.Fragment>
      <Header />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="c-theLogin">
              <div className="theTitle">
                <h3>Log in to your account</h3>
                <p>Sign into your account to track and view your orders</p>
              </div>
              <div className="c-theForm">
                <p className="label">Real Name</p>
                <TextField
                  className="form-control"
                  placeholder="Type your username or email address"
                  name="email"
                  onChange={handleChange}
                />
                <p className="label">Password</p>
                <TextField
                  className="form-control"
                  placeholder="Type your password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                />
                <div className="theFlex">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChangeCheckbox}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />
                  <div>
                    <Link style={{ color: "#565656" }}>Forget Password</Link>
                  </div>
                </div>
                <div className="c-theLogin">
                  <Link
                    className="btn-primary theLoginBtn"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="c-theRegister">
              <div className="theTitle">
                <h3>In New Customer</h3>
                <p>
                  Create new account to keep track of all your purchase and view
                  the best product for you
                </p>
              </div>
              <div className="c-theForm">
                <p className="label">Email Address</p>
                <TextField
                  name="email"
                  className="form-control"
                  placeholder="Type your email address"
                  onChange={handleChangeRegister}
                />

                <div className="c-theRegister">
                  <Link className="btn-primary theRegisterBtn" onClick={()=>handleRegister()}>Register</Link>
                </div>
                <div className="c-theSub">
                  <h4>Sign up today and you will be able to:</h4>
                  <div className="theFlex">
                    <CheckIcon style={{ color: "#8cda7c" }} />
                    <p>Speed your way through the checkout</p>
                  </div>
                  <div className="theFlex">
                    <CheckIcon style={{ color: "#8cda7c" }} />
                    <p>Track your orders easily</p>
                  </div>
                  <div className="theFlex">
                    <CheckIcon style={{ color: "#8cda7c" }} />
                    <p>Keep a record of all your purchases</p>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
