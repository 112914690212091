import React, {
  useState,
  useEffect
} from "react";
import {
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Slider from "react-slick";
import {
  get_category,
  get_recommend_product_by_category,
  get_recommended_product_web,
  search_warranty,
  get_special_deal_product
} from "../../api/API";

import banner001 from "../../assets/images/home-banner1.png";
// import banner001 from "../../assets/images/home-banner.png";
import banner002 from "../../assets/images/warranty-portal.png";
import banner003 from "../../assets/images/service-portal.png";
import logo from "../../assets/images/easysettle.png";
import shipping from "../../assets/images/shipping.png";
import setting from "../../assets/images/setting.png";
import tag from "../../assets/images/tag.png";
import prod001 from "../../assets/images/wall-mounted.png";
import fresh from "../../assets/images/fresh.png";
import job from "../../assets/images/bannerServices.png";
import WarrantyRegisterModal from "./WarrantyRegisterModal";
import aircorn from "../../assets/images/aircon.png"
import waterheater from "../../assets/images/waterheater.png"
import Product001 from "../../assets/images/product01.jpg"
import Product002 from "../../assets/images/product02.jpg"
import Product003 from "../../assets/images/product03.jpg"
import Product004 from "../../assets/images/product04.jpg"
import Product005 from "../../assets/images/product05.jpg"

export default function Home() {

  const history = useHistory();
  let { Home } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Home]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  const settings2 = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,

          dots: false,
        },
      },
    ],
  };
  const [registerWarranty, setRegisterWarranty] = useState(false);
  const [next, setNext] = useState(false);

  const handleClose = () => {
    setRegisterWarranty(false);
    setNext(false);
  };

  // handle filter
  const [form, setForm] = useState({
    serial_no: "",
  });

  const handleChangeField = (event) => {
    event.persist();
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFilterWarranty = async () => {
    var res = await search_warranty({
      ...form,
    });
    if (res.status) {
      // console.log(res.data[0].product_warranty_id, {...form})
      history.push("/warrantydetail/" + res.product_warranty_id[0], {
        ...form,
      });
    } else {
      alert(res.message);
    }
  };

  const [category, setCategory] = useState([]);
  const [recommend, setRecommend] = useState([]);
  const [specialDeal, setSpecialDeal] = useState(null);
  const [recommendcategory_cooling, setRecommendCategory_Cooling] =
    useState([]);
  const [recommendcategory_kitchen, setRecommendCategory_Kitchen] =
    useState([]);

  useEffect(() => {
    let post_data_cooling = {
      category_id: 1,
    };
    let post_data_kitchen = {
      category_id: 24,
    };
    get_category().then((json) => {
      var temp_data = []
      for (var i = 0; i < 4; i++) {
        temp_data.push(json.data[i])
      }
      // setCategory(json.data);
      setCategory(temp_data);
    });
    get_recommended_product_web().then((json) => {
      setRecommend(json.data);
    });
    get_recommend_product_by_category(post_data_cooling).then((json) => {
      setRecommendCategory_Cooling(json.data);
    });
    get_recommend_product_by_category(post_data_kitchen).then((json) => {
      setRecommendCategory_Kitchen(json.data);
    });
    get_special_deal_product({ is_see_all: 1 }).then((json) => {
      setSpecialDeal(json.data);
    });
  }, []);
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="c-banner">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7}>
              <div className="thebanner001">
                <img src={banner001} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} className="theColumn">
              <div className="thebanner002">
                <img src={banner002} />
                <div className="thedetail">
                  <p className="bold">
                    <span
                      style={{ marginBottom: "1px", display: "block" }}
                      className="color-blueBold"
                    >
                      EasySettle
                    </span>
                    Warranty Portal
                  </p>
                  <Link
                    onClick={() => {
                      setRegisterWarranty(true);
                    }}
                    className="btn-primary"
                  >
                    Register Now
                  </Link>
                  <div className="theFlexForm">
                    <div className="theFFtextInput">
                      <TextField
                        className="form-control"
                        placeholder="Serial Number"
                        name="serial_no"
                      // value={form.serial_no}
                      // onChange={handleChangeField}
                      />
                    </div>
                    <div className="theFFbutton">
                      <Link
                        onClick={() => handleFilterWarranty()}
                        className="btn-primary"
                      >
                        Check
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <Link className="thebanner003" to="/joblist">
                <img src={banner003} />
                <div className="thedetail_">
                  <p>Get a job from our available list</p>
                  <img src={logo} />
                  <span>
                    Go to Job Portal <KeyboardArrowRightIcon />
                  </span>
                </div>
              </Link>
            </Grid>
          </Grid>
        </div>
        <div className="c-thelist">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <div className="thebox">
                <div className="theicon">
                  <img src={shipping} />
                </div>
                <div className="thedetail">
                  <p>Free Delivery</p>
                  <span>within Johor Bahru Area</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className="thebox">
                <div className="theicon">
                  <img src={setting} />
                </div>
                <div className="thedetail">
                  <p>Free Delivery</p>
                  <span>within Johor Bahru Area</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className="thebox_">
                <div className="theicon">
                  <img src={tag} />
                </div>
                <div className="thedetail">
                  <p>Free Delivery</p>
                  <span>within Johor Bahru Area</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="c-thelist2">
          <h3>Featured Categories</h3>
          <Grid container spacig={3}>
            {category.map((item) => (
              <Grid item xs={6} sm={3} md={3}>
                <Link
                  className="theListBox"
                  to={`../category/${item.category_id}`}
                >
                  <div className="theDetail">
                    <img src={item.image} />
                    <p>{item.name}</p>
                  </div>
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
      <div className="c-thelist4">
        <div className="c-theimg">
          <div className="title">
            <p>STAY COOL</p>
            <p>
              <span>STAY</span> FRESH
            </p>
          </div>
          <img src={fresh} />
        </div>
        <div className="c-slider_">
          <Slider {...settings2}>
            {specialDeal != null && specialDeal.map((item) =>
              <div>
                <Link to={`../productdetail/` + item.product_id}>
                  <div className="c-theSliderBox">
                    <img src={item.product_image} />
                    {/* <p className="price">RM {item.discount_price}</p> */}
                    {item.brand != null &&
                      <p className="discount">{item.brand}</p>
                    }
                    <p className="sub">{item.name}</p>
                  </div>
                </Link>
              </div>
            )}
            {/* {recommendcategory_cooling.map((item) => (
              <div>
                <Link to={`../productdetail/${item.product_id}`}>
                  <div className="c-theSliderBox">
                    <img src={item.product_image} />
                    <p className="discount">{item.brand}</p>
                    <p className="sub">{item.name}</p>
                  </div>
                </Link>
              </div>
            ))} */}
          </Slider>
        </div>
      </div>

      <Container>
        <div className="theContructor">
          <Link className="theCardContrustor"
          //  to="/joblist"
          >
            <div className="theCardIMG">
              <img src={aircorn} />
            </div>
            <p className="title">Air Conditional</p>
            <p className="btnDetail">View Showcase</p>
          </Link>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Link className="theCardContrustor" to="/joblist">
                <div className="theCardIMG">
                  <img src={aircorn} />
                </div>
                <p className="title">Air Conditional</p>
                <p className="btnDetail">View Showcase</p>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Link className="theCardContrustor" to="/constructor">
                <div className="theCardIMG">
                  <img src={waterheater} />
                </div>
                <p className="title">Water Heater</p>
                <p className="btnDetail">View Showcase</p>
              </Link>
            </Grid>
          </Grid> */}
        </div>
      </Container>

      <Footer />
      <WarrantyRegisterModal
        open={registerWarranty}
        next={next}
        setNext={setNext}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
}
