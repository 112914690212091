import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
//image
import proof from "../../assets/images/proof.png";

import { useDropzone } from "react-dropzone";

import { upload_proof_image } from "../../api/API";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
}));

export default function Previews(props) {
  const [files, setFiles] = React.useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles)
      if (acceptedFiles) {
        const file = acceptedFiles[0];
        console.log(file);
        var formData = new FormData();
        formData.append("image", file);
        formData.append("dummy", "abc");

        var res = await upload_proof_image(formData);
        if (res) {
          setFiles(res.data.receipt);
          props.onUploaded(res);
          console.log(res);
        }else{
          alert(res.message)
        }
      }
    },
  });

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumbsContainer = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  return (
    <section className="container">
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ cursor: "pointer" }}
        className="c-image"
      >
        <input {...getInputProps()} />
          <>
            {files == "" ? (
              <div className="c-default-image">
                <img src={proof} className="default-image" />
              </div>
            ) : (
              <aside style={thumbsContainer}>
                <div style={thumb}>
                  <div style={thumbInner}>
                    <img  src={'http://178.128.126.187/easysettle_admin/' + files} style={img} />
                  </div>
                </div>
              </aside>
            )}
          </>
      </div>
    </section>
  );
}
