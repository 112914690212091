import React from "react";
import { Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import ListingPage from "./pages/Listing/Listing";
import ProductDetailPage from "./pages/ProductDetail/ProductDetail";
import ComparePage from "./pages/Compare/Compare";
import CategoryPage from "./pages/Category/Category";
import WarrantyPage from "./pages/Warranty/Warranty";
import WarrantyDetailPage from "./pages/WarrantyDetail/WarrantyDetail";
import SignUpPage from "./pages/SignUp/SignUp";
import ServicesPage from "./pages/Services/Services";
import ServiceDetailPage from "./pages/ServiceDetail/ServiceDetail";
import CoinRewardPage from "./pages/CoinReward/CoinReward";
import PointRewardPage from "./pages/PointReward/PointReward";
import ProfilePage from "./pages/Profile/Profile";
import ReferFriendPage from "./pages/ReferFriend/ReferFriend";
import ConstructorPage from "./pages/Constructor/Constructor";
import JobListPage from "./pages/JobList/JobList";
import AddressPage from "./pages/Address/Address";
import OrderPage from "./pages/Order/Order";
import OrderDetailPage from "./pages/OrderDetail/OrderDetail";
import RegisterPage from "./pages/Register/Register";
import LoginPage from "./pages/Login/Login";
import BecomeConstructorPage from "./pages/BecomeConstructor/BecomeConstructor";
import JobDetailPage from "./pages/JobDetail/JobDetail";
import ConstructorServiceDetailPage from "./pages/ConstructorServiceDetail/ConsturctorServiceDetail";
import AddOnPage from "./pages/AddOn/AddOn";
import CheckoutPage from "./pages/Checkout/Checkout";
import ShoppingCartPage from "./pages/ShoppingCart/ShoppingCart";
import TermConditionPage from "./pages/StaticPage/TermsCondition";
import PrivacyPolicyPage from "./pages/StaticPage/PrivacyPolicy";
import PromotionPolicyPage from "./pages/StaticPage/PromotionPolicy";
import ShippingDeliveryPage from "./pages/StaticPage/ShippingDelivery";
import CancellationPolicyPage from "./pages/StaticPage/CancelPolicy";
import ReturnExchangePolicyPage from "./pages/StaticPage/ReturnExchangePolicy";
import RefundPolicyPage from "./pages/StaticPage/RefundPolicy";
import AdminFeePage from "./pages/StaticPage/AdminFee";

export default function Routes(category, product) {
  return (
    <div>
      <Route
        exact
        path="/"
        render={(props) => <HomePage {...props}></HomePage>}
      />
      <Route
        exact
        path="/listing"
        render={(props) => <ListingPage {...props}></ListingPage>}
      />
      <Route
        exact
        path="/productdetail/:product_id"
        render={(props) => (
          <ProductDetailPage
            {...props}
            key={product.product_id}
          ></ProductDetailPage>
        )}
      />
      <Route
        exact
        path="/compare"
        render={(props) => <ComparePage {...props}></ComparePage>}
      />
      <Route
        exact
        path="/category/:category_id"
        render={(props) => (
          <CategoryPage {...props} key={category.category_id}></CategoryPage>
        )}
      />

      <Route
        exact
        path="/warranty"
        render={(props) => <WarrantyPage {...props}></WarrantyPage>}
      />
      <Route
        exact
        path="/warrantydetail/:product_warranty_id"
        render={(props) => <WarrantyDetailPage {...props}></WarrantyDetailPage>}
      />
      <Route
        exact
        path="/signup"
        render={(props) => <SignUpPage {...props}></SignUpPage>}
      />
      <Route
        exact
        path="/services"
        render={(props) => <ServicesPage {...props}></ServicesPage>}
      />
      <Route
        exact
        path="/servicedetail/:service_list_id"
        render={(props) => <ServiceDetailPage {...props}></ServiceDetailPage>}
      />
      <Route
        exact
        path="/coinreward"
        render={(props) => <CoinRewardPage {...props}></CoinRewardPage>}
      />
      <Route
        exact
        path="/pointreward"
        render={(props) => <PointRewardPage {...props}></PointRewardPage>}
      />
      <Route
        exact
        path="/profile"
        render={(props) => <ProfilePage {...props}></ProfilePage>}
      />
      <Route
        exact
        path="/referfriend"
        render={(props) => <ReferFriendPage {...props}></ReferFriendPage>}
      />
      <Route
        exact
        path="/constructor"
        render={(props) => <ConstructorPage {...props}></ConstructorPage>}
      />
      <Route
        exact
        path="/joblist"
        render={(props) => <JobListPage {...props}></JobListPage>}
      />
      <Route
        exact
        path="/address"
        render={(props) => <AddressPage {...props}></AddressPage>}
      />
      <Route
        exact
        path="/order"
        render={(props) => <OrderPage {...props}></OrderPage>}
      />
      <Route
        exact
        path="/orderdetail/:orders_id"
        render={(props) => <OrderDetailPage {...props}></OrderDetailPage>}
      />
      <Route
        exact
        path="/register"
        render={(props) => <RegisterPage {...props}></RegisterPage>}
      />
      <Route
        exact
        path="/login"
        render={(props) => <LoginPage {...props}></LoginPage>}
      />
      <Route
        exact
        path="/becomeconstructor"
        render={(props) => <BecomeConstructorPage {...props}></BecomeConstructorPage>}
      />
      <Route
        exact
        path="/jobdetail"
        render={(props) => <JobDetailPage {...props}></JobDetailPage>}
      />
      <Route
        exact
        path="/constructorservicedetail"
        render={(props) => <ConstructorServiceDetailPage {...props}></ConstructorServiceDetailPage>}
      />
      <Route
        exact
        path="/addon"
        render={(props) => <AddOnPage {...props}></AddOnPage>}
      />
      <Route
        exact
        path="/checkout"
        render={(props) => <CheckoutPage {...props}></CheckoutPage>}
      />
      <Route
        exact
        path="/shoppingcart"
        render={(props) => <ShoppingCartPage {...props}></ShoppingCartPage>}
      />
      <Route
        exact
        path="/term_and_condition"
        render={(props) => <TermConditionPage {...props}></TermConditionPage>}
      />
      <Route
        exact
        path="/privacy_policy"
        render={(props) => <PrivacyPolicyPage {...props}></PrivacyPolicyPage>}
      />
      <Route
        exact
        path="/promotion_policy"
        render={(props) => <PromotionPolicyPage {...props}></PromotionPolicyPage>}
      />
      <Route
        exact
        path="/shipping_and_delivery"
        render={(props) => <ShippingDeliveryPage {...props}></ShippingDeliveryPage>}
      />
      <Route
        exact
        path="/cancellation_policy"
        render={(props) => <CancellationPolicyPage {...props}></CancellationPolicyPage>}
      />
      <Route
        exact
        path="/return_exchange_policy"
        render={(props) => <ReturnExchangePolicyPage {...props}></ReturnExchangePolicyPage>}
      />
      <Route
        exact
        path="/refund_policy"
        render={(props) => <RefundPolicyPage {...props}></RefundPolicyPage>}
      />
      <Route
        exact
        path="/admin_fee"
        render={(props) => <AdminFeePage {...props}></AdminFeePage>}
      />

    </div>
  );
}
